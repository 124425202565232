import { ProfileInfo } from "@wissenswerft/core/authentication";

import { Role, StaffMember } from "@wissenswerft/organizational-structure";

export class StaffMemberViewModel {
    
    private staffMember: StaffMember;

    get id(): number { return this.staffMember.id; }

    get ident(): string { return this.staffMember.ident; }

    get firstname(): string { return this.staffMember.firstname; }

    get lastname(): string { return this.staffMember.lastname; }

    get eMail(): string { return this.staffMember.eMail; }

    get role(): Role { return this.staffMember.role; }

    get responsible(): any {
        if (this.staffMember?.responsible) {
            return this.staffMember?.responsible.id || this.staffMember?.responsible;
        }
    }

    set ident(data: string) { this.staffMember.ident = data; }
    
    set firstname(data: string) { this.staffMember.firstname = data; }

    set lastname(data: string) { this.staffMember.lastname = data; }

    set eMail(data: string) { this.staffMember.eMail = data; }

    set role(data: Role) { this.staffMember.role = data; }

    set responsible(data) { this.staffMember.responsible = data; }

    constructor(data: StaffMember) {
        this.staffMember = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'label', 'firstname', 'lastname', 'eMail', 'responsible', 'role'
];