import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ProfileInfo } from '@wissenswerft/core/authentication';

import { SpaceOneCoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';

import { SharedDataService } from '@wissenswerft/organizational-structure';

import { ToastType } from '@wissenswerft/ww-library';

import { DxFormComponent, DxNumberBoxComponent, DxPopupComponent, DxSelectBoxComponent, DxTreeListComponent } from 'devextreme-angular';

import { Column } from 'devextreme/ui/data_grid';

import { Observable, Subscription } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';

import { DataDefinitionOptions } from '../../models/customer-space.model';

import { Measure, MeasureTask } from '../../models/measure.model';

import { AppService } from '../../services/app.service';

import { DataService } from '../../services/data.service';

import { DataDefinitionViewModel } from '../../view-models/customer-space.view-model';

import { MeasureTaskViewModel, VISIBLE_PROPERTIES } from '../../view-models/measure-task.view-model';

import { MeasureTaskService } from './measure-task.service';

@Component({
  selector: 'ubt-measure-task',
  templateUrl: './measure-task.component.html',
  styleUrls: ['./measure-task.component.scss']
})
export class MeasureTaskComponent implements OnInit, OnDestroy {

  @ViewChild('meausreTaskTree') meausreTaskTree: DxTreeListComponent;

  @ViewChild('createTaskPopup') createTaskPopup: DxPopupComponent;

  @ViewChild('measure') measure: DxSelectBoxComponent;

  @ViewChild('degreeOfImplementationField') degreeOfImplementationField: DxNumberBoxComponent;

  @ViewChild('confirmTaskPopup') confirmTaskPopup: DxPopupComponent;
  @ViewChild('form') form: DxFormComponent;

  public measureTask: MeasureTask = new MeasureTask(null);

  public title: string;

  public pluralTitle: string;

  public responsibles: ProfileInfo[] = [];

  public measureTasks: MeasureTaskViewModel[];

  public allowedParentTasks: MeasureTask[] = [];

  public columnsHeader: Column[] = [];

  public showLoader = false;

  public measureTaskId: number;

  public measures: Measure[] = [];

  public updateTaskMode: boolean = false;

  public showTaskParents: boolean = false;

  public priorities: DataDefinitionOptions[] = [
    { name: this.dataService.res('Ubt-MeasureDetail-Milestone'), value: false },
    { name: this.dataService.res('Ubt-MeasureDetail-Task'), value: true }
  ];
  private subscriptions: Subscription[] = [];
  public submitButtonText: string = 'Create';
  public cancelButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Cancel'),
    onClick: () => this.clearWindow()
  };

  constructor(
    public dataService: DataService,

    private persistenceService: PersistenceService,

    private sharedDataService: SharedDataService,

    private coreDataService: SpaceOneCoreDataService,

    public appService: AppService,

    private cdr: ChangeDetectorRef,

    public measureTaskService: MeasureTaskService
  ) {
  }

  ngOnInit(): void {

    this.showLoader = true;
    
    this.measureTask = new MeasureTask(null);

    this.subscriptions.push(this.coreDataService.getAllAccounts<ProfileInfo[]>().pipe(switchMap(responsibles => {
      return this.measureTaskService.measures
        .pipe(switchMap(measures => {
          return this.sharedDataService.getDefinitionAndData<MeasureTask[]>(TypeKey.measureTask)
            .pipe(map(data => ({ responsibles, measures, data })));
        }))
    })).subscribe(({ responsibles, measures, data }) => {
      this.responsibles = responsibles;
      this.measures = measures;
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.measureTask] = definitions;
      this.title = definitions.name;
      this.pluralTitle = definitions.namePlural;
      const measureTasks = data[1];
      this.measureTasks = [];
      const measureTaskDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.measureTask].definitionVM = measureTaskDefinitionVM;
      const properties = measureTaskDefinitionVM.properties;
      for (const key in properties) {
        if (properties[key] && properties[key].key !== 'ident') {
          const property = properties[key];
          this.columnsHeader.push({
            dataField: property.key,
            caption: property.label,
            visible: property.key === 'efficiency' ? false : property?.visible,
            visibleIndex: this.getVisibleIndex(property.key),
            dataType: this.dataService.getDataType(property.type),
            showInColumnChooser: property.key === 'efficiency' ? false : true,
            customizeText: text => {
              if (property.key === 'degreeOfImplementation') {
                if (text.valueText) return text.valueText + '%';
              }
              else {
                return text.valueText;
              }
            },
            lookup: (this.getLookupColumn(property.key)) ? {
              dataSource: this.getLookupColumn(property.key).dataSource,
              displayExpr: this.getLookupColumn(property.key).displayExpr,
              valueExpr: this.getLookupColumn(property.key).valueExpr
            } : undefined,
          });
        }
      }

      this.columnsHeader.push({
        type: "buttons",
        caption: '',
        minWidth: 70,
        dataField: '',
        buttons: [{
          icon: 'edit',
          onClick: (e) => { this.openEditMeasureTask(e); }
        }, {
          icon: "trash",
          onClick: (e) => { this.openConfirmTaskDialog(e); }
        }]
      })

      measureTasks.map((measureTask => {
        if (!measureTask['meta'].parent) {
          this.allowedParentTasks.push(measureTask);
        } else {
          measureTask.parentId = measureTask['meta'].parent;
        }
        this.measureTasks.push(new MeasureTaskViewModel(measureTask));
        this.cdr.detectChanges();
      }));
    }, error => {
      console.error(error);
      this.showLoader = false;
    }, () => {
      this.showLoader = false;
    }));

    if (this.sharedDataService.definitionsVM[TypeKey.measureTask]) {
      this.title = this.sharedDataService.definitionsVM[TypeKey.measureTask].name;
    }

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((measureTask: MeasureTaskViewModel) => {
      this.measureTasks.push(measureTask);
    }));
  }

  public removeRow(event): void {
    this.subscriptions.push(this.persistenceService.addObjectForDelete(this.measureTaskId).subscribe(() => {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
      this.confirmTaskPopup.instance.hide();
      this.loadAssignedTasks()
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public abortTaskDelete() {
    this.confirmTaskPopup.instance.hide();
  }

  private loadAssignedTasks = (): void => {
    this.allowedParentTasks = [];
    this.measureTasks = [];
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<MeasureTask[]>(TypeKey.measureTask).subscribe((data) => {
      const measureTasks = data[1];
      measureTasks.map((measureTask => {
        if (!measureTask['meta'].parent) {
          this.allowedParentTasks.push(measureTask)
        } else {
          measureTask.parentId = measureTask['meta'].parent;
        }
        this.measureTasks.push(new MeasureTaskViewModel(measureTask));
        this.cdr.detectChanges();
      }));
    }, error => {
      console.error(error);
    }, () => {
    }));
  }

  public openEditMeasureTask = (event) => {
    this.submitButtonText = 'Update';
    this.measureTask = event.row.data.measureTask;
    this.updateTaskMode = true;
    if (this.measureTask.parentId) {
      this.showTaskParents = true;
    } else {
      this.showTaskParents = false;
    }
    this.cdr.detectChanges();
    this.createTaskPopup.instance.show();
  }

  public openConfirmTaskDialog(event): void {
    this.measureTaskId = event.row.data.id || event.row.key;
    this.confirmTaskPopup.instance.show();
  }

  public persistMeasureTask = (event) => {
    event.preventDefault();
    if (this.updateTaskMode === true) {
      this.updateRow();
    } else {
      this.createMeasureTask();
    }
  }

  public updateRow(): void {
    delete this.measureTask.meta.updated;
    if (this.measureTask.parentId) {
      this.measureTask.meta.parent = this.measureTask.parentId;
      delete this.measureTask.parentId;
    }
    const measureTaskId = this.measureTask.id;
    const measureTask: MeasureTask = new MeasureTask(this.measureTask);
    const query = this.measureTaskService.prepareTaskPersistObject(measureTask);
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.measureTask].definitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.measureTask].definitionVM.listProperties;
    const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
    this.subscriptions.push(this.persistenceService.addObjectForUpdate(measureTaskId, object).subscribe(() => {
      this.updateTaskMode = false;
      this.clearWindow();
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
      this.loadAssignedTasks();
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'measure':
        return { valueExpr: 'id', displayExpr: 'title', dataSource: this.measures };
      case 'responsible':
        return { valueExpr: 'id', displayExpr: this.dataService.getResponsibleFullName, dataSource: this.responsibles };
      case 'targetMaturityLevel':
        return { valueExpr: 'value', displayExpr: 'name', dataSource: this.measureTaskService.maturityLevel };
    }
  }

  private getVisibleIndex(key: string) {
    switch (key) {
      case 'label':
        return 0;
      case 'degreeOfImplementation':
        return 1;
      case 'measure':
        return 2;
      case 'responsible':
        return 3;
      case 'targetMaturityLevel':
        return 4;
      case 'startDate':
        return 5;
    }
  }

  public openMeasureTaskDialog(): void {
    this.submitButtonText = 'Create';
    this.createTaskPopup.instance.show();
  }

  public createMeasureTask(): void {
    let parentId: number;
    if (this.measureTask.parentId) {
      parentId = this.measureTask.parentId;
      delete this.measureTask.parentId;
    }
    this.subscriptions.push(this.persistObject().subscribe((data: MeasureTask) => {
      if (parentId) {
        data.parentId = parentId;
        this.measureTask = data;
        delete this.measureTask.meta.updated;
        if (this.measureTask.parentId) {
          this.measureTask.meta.parent = this.measureTask.parentId;
          delete this.measureTask.parentId;
        }
        const measureTask: MeasureTask = new MeasureTask(this.measureTask);
        const query = this.measureTaskService.prepareTaskPersistObject(measureTask);
        const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.measureTask].definitionVM.multilingualProperties;
        const listProperties = this.sharedDataService.definitionsVM[TypeKey.measureTask].definitionVM.listProperties;
        const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
        this.subscriptions.push(this.persistenceService.addObjectForUpdate(this.measureTask.id, object).subscribe(() => {
          this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
          this.loadAssignedTasks();
        }, error => {
          this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
        }));
      }


      const measureTask = new MeasureTaskViewModel(data);
      this.sharedDataService.updateGridData(measureTask);
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
      this.clearWindow();
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
    this.measureTask = new MeasureTask(null);
  }

  private persistObject(): Observable<MeasureTask> {
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.measureTask].definitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.measureTask].definitionVM.listProperties;
    this.measureTask.startDate?.setDate(this.measureTask.startDate.getDate() + 1);
    const query = this.sharedDataService.createPersistObject(this.measureTask, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.measureTask, query);
  }

  public onCellPrepared = (e): void => {
    if ((e.column.dataType == 'number') && (e.rowType === 'data')) {
      e.cellElement.style.textAlignLast = 'center';
    }
  }

  public clearWindow(): void {
    this.createTaskPopup.instance.hide();
    this.meausreTaskTree.instance.repaint();
    this.measureTask = new MeasureTask(null);
    this.form.instance.resetValues();
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }
}