import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';
import { Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';
import { NamedItemComponent } from '@wissenswerft/organizational-structure';

@Component({
  selector: 'ubt-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements AfterViewInit, OnDestroy{
  @ViewChild('namedItem') namedItem: NamedItemComponent;
  public typeKey: TypeKey = TypeKey.tag;
  private subscriptions : Subscription[] = [];

  constructor(public dataService: DataService) { }

  ngAfterViewInit(): void {
    this.namedItem.showIdent=false;
    this.namedItem.showNotification.subscribe(message => {
      this.dataService.appService.callNotification(message);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }
}