import { Injectable } from '@angular/core';
import { MeasureTask } from '../../models/measure.model';
import { SpaceOneCoreDataService, TypeKey } from '@wissenswerft/core/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Measure } from '../../models/measure.model';
import { DataDefinitionOptions } from '../../models/customer-space.model';

@Injectable({
  providedIn: 'root'
})
export class MeasureTaskService {
  private _measures: Observable<Measure[]>;
  public measureById: {}
  
  constructor(private coreDataService: SpaceOneCoreDataService) { }

  public degree = [
    { id: 0, value: "0%" },
    { id: 25, value: "25%" },
    { id: 50, value: "50%" },
    { id: 75, value: "75%" },
    { id: 100, value: "100%" }
  ];

  public maturityLevel: DataDefinitionOptions[] = [
    { name: 'HG1', value: 'HG1' },
    { name: 'HG2', value: 'HG2' },
    { name: 'HG3', value: 'HG3' },
    { name: 'HG4', value: 'HG4' },
    { name: 'HG5', value: 'HG5' }
  ];

  public prepareTaskPersistObject(measureTask: MeasureTask): MeasureTask {
    const query: MeasureTask = new MeasureTask(null);
    query.ident = measureTask.ident;
    query.label = measureTask.label;
    query.degreeOfImplementation = measureTask.degreeOfImplementation;
    query.efficiency = measureTask.efficiency;
    query.notes = measureTask.notes;
    query.measure = measureTask.measure;
    query.startDate = measureTask.startDate;
    query.targetDate = measureTask.targetDate;
    query.responsible = measureTask.responsible;
    query.targetMaturityLevel = measureTask.targetMaturityLevel;
    query.collaborators = measureTask.collaborators;
    query.totalTimeRequired = measureTask.totalTimeRequired;
    query.meta = measureTask.meta;
    return query;
  }

  public get measures(): Observable<Measure[]> {
    if (!this._measures) {
      this._measures = this.coreDataService.getItemsByName<Measure[]>(TypeKey.measure).pipe(map(measures => {
        this.measureById = {};
        measures.forEach((measure => {
          measure = new Measure(measure);
          this.measureById[measure.id] = measure;
        }));
        return measures;
      }));
    }
    return this._measures;
  }
}