import { Tag } from "@wissenswerft/organizational-structure";
import { Measure, Status } from "../models/measure.model";

export class MeasureViewModel {
    protected measure: Measure;

    private _subProjectLabel: string

    get ident(): string { return this.measure.ident; }

    get title(): string { return this.measure.title; }

    get goal(): string { return this.measure.goal; }

    get currentGrade(): string { return this.measure.currentGrade; }

    get currentState(): string { return this.measure.currentState; }

    get startDate(): Date {
        if (this.measure?.startDate) {
            return new Date(this.measure?.startDate);
        }
    }

    get endDate(): Date {
        if (this.measure?.endDate) {
            return new Date(this.measure?.endDate);
        }
    }

    get creationDate(): Date {
        if (this.measure?.meta.created) {
            return new Date(this.measure.meta.created);
        }
    }

    get status(): Status { return this.measure.status; }

    get tags(): Tag[] { return this.measure.tags; }

    get measureBenefit() { return this.measure.benefits; }

    get subProject(): string { return this._subProjectLabel; }

    set subProject(title: string) { this._subProjectLabel = title; }

    get responsible() {
        if (this.measure?.responsible) {
            return this.measure?.responsible.id || this.measure?.responsible;
        }
    }

    constructor(data: Measure) {
        this.measure = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'title', 'responsible', 'subProject', 'endDate', 'ident', 'currentGrade', 'status'
];

export const MEASURE_TASK_VISIBLE_PROPERTIES = [
    'label', 'responsible', 'targetDate', 'targetMaturityLevel'
];