import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { GridComponent, ToastType, Mode } from '@wissenswerft/ww-library';
import { DxPopupComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Measure } from '../../models/measure.model';
import { DataService } from '../../services/data.service';
import { MeasureService } from './measure.service';
import { DataDefinitionViewModel } from '../../view-models/customer-space.view-model';
import { MeasureViewModel, VISIBLE_PROPERTIES } from '../../view-models/measure.view-model';
import { Collaborator, MeasureProgress, Resource, SharedDataService } from '@wissenswerft/organizational-structure';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'ubt-measure',
  templateUrl: './measure-list.component.html',
  styleUrls: ['./measure-list.component.scss']
})
export class MeasureListComponent implements OnInit {
  @ViewChild('measureDetailsPopup') measureDetailsPopup: DxPopupComponent;
  @ViewChild('confirmPopup') confirmPopup: DxPopupComponent;
  @ViewChild('measureGrid') measureGrid: GridComponent;
  public selectedMeasures: Measure[];
  public selectionMode: Mode = Mode.Multiple;
  public measures: MeasureViewModel[];
  public columnsHeader: Column[] = [];
  public title: string;
  public pluralTitle: string;
  public showLoader = false;
  public propertiesDefinition: object;
  private groupByColumnIndex = [];
  private responsibles: ProfileInfo[];
  private measureId: number;
  private subscriptions: Subscription[] = [];

  constructor(
    public dataService: DataService,
    public appService: AppService,
    private sharedDataService: SharedDataService,
    private router: Router,
    private measureService: MeasureService,
    private persistenceService: PersistenceService
  ) { }

  ngOnInit(): void {

    this.showLoader = true;

    this.dataService.checkGridGroupByColumns('measureGrid', this.groupByColumnIndex);

    if (this.sharedDataService.definitionsVM[TypeKey.measure]) {
      this.title = this.sharedDataService.definitionsVM[TypeKey.measure].name;
    }

    this.subscriptions.push(this.sharedDataService.responsibles.pipe(switchMap(responsibles => {
      return this.sharedDataService.getDefinitionAndData<Measure[]>(TypeKey.measure)
        .pipe(map(data => ({ responsibles, data })));
    }))
      .subscribe(({ responsibles, data }) => {
        this.responsibles = responsibles;
        const definitions = data[0];
        this.sharedDataService.definitionsVM[TypeKey.measure] = definitions;
        this.title = definitions.name;
        this.pluralTitle = definitions.namePlural;
        this.measureService.measures = data[1];
        const measures = data[1];
        this.measures = [];
        const measureDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
        this.sharedDataService.definitionsVM[TypeKey.measure].measureDefinitionVM = measureDefinitionVM;
        const properties = measureDefinitionVM.properties;
        this.propertiesDefinition = properties;
        for (const key in properties) {
          if (properties[key] && properties[key].key !== 'ident') {
            const property = properties[key];
            this.columnsHeader.push({
              dataField: property.key,
              caption: property.label === 'Goal' ? 'Objective' : property.label,
              visible: property?.visible,
              visibleIndex: this.getVisibleIndex(property.key),
              dataType: this.dataService.getDataType(property.type),
              groupIndex: (property.key === 'subProject') ? 0 : (this.groupByColumnIndex[property.key]) ? this.groupByColumnIndex[property.key] : undefined,
              lookup: (this.getLookupColumn(property.key)) ? {
                dataSource: this.getLookupColumn(property.key).dataSource,
                displayExpr: this.getLookupColumn(property.key).displayExpr,
                valueExpr: this.getLookupColumn(property.key).valueExpr
              } : undefined,
            });
          }
        }
        this.columnsHeader.push(
          {
            dataField: 'creationDate',
            caption: (sessionStorage.getItem('fixedCulture') === "de") ? 'Erstellungsdatum' : 'Creation date',
            visible: true,
            dataType: 'date'
          },
          {
            type: "buttons",
            caption: '',
            alignment: 'left',
            minWidth: 70,
            dataField: 'edit',
            buttons: [{
              icon: 'edit',
              onClick: (e) => { this.openMeasureDetail(e); }
            }, {
              icon: "trash",
              onClick: (e) => { this.openConfirmDialog(e); }
            }]
          });

        measures.map((measure: Measure) => {
          const measureViewModel = new MeasureViewModel(measure);
          measureViewModel.subProject = measure.subProject.ident;
          this.measures.push(measureViewModel);
        });
      }, error => {
        console.error(error);
        this.showLoader = false;
      }, () => {
        this.showLoader = false;
      }));

    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<Resource[]>(TypeKey.resource).subscribe((data) => {
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.resource] = definitions;
      const definitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.resource].definitionVM = definitionVM;
    }));

    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<MeasureProgress[]>(TypeKey.measureProgress).subscribe(data => {
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.measureProgress] = definitions;
      const definitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.measureProgress].definitionVM = definitionVM;
    }));

    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<Collaborator[]>(TypeKey.collaborator).subscribe(data => {
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.collaborator] = definitions;
      const definitionVM = new DataDefinitionViewModel(definitions, ['name', 'timeSpent']);
      this.sharedDataService.definitionsVM[TypeKey.collaborator].collaboratorDefinitionVM = definitionVM;
    }));

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((measure: Measure) => {
      const measureViewModel = new MeasureViewModel(measure);
      measureViewModel.subProject = measure.subProject.ident;
      this.measures.push(measureViewModel);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

  public openMeasureDetail(event?): void {
    if (!event) this.measureService.selectedMeasures = this.selectedMeasures;
    const measureDetail = event ? new Measure(event.data?.measure || event.row?.data.measure) : new Measure(this.selectedMeasures[0]);
    this.dataService.sendMeasureDetail(measureDetail);
    this.dataService.appService.showMeasureIcon = true;
    this.router.navigate(['measureDetail', 'measures', measureDetail.id]);
  }

  public selectMeasure(event): void {
    if (event.columnIndex == 0) {
      if (!this.selectedMeasures) {
        this.selectedMeasures = [];
      }
      if (event.rowIndex == 0) {
        this.measureGrid.getVisibleRows().filter(row => row.rowType == 'data').forEach(element => {
          this.updateSelectedMeasures(element.key?.measure);
        });
      } else {
        this.updateSelectedMeasures(event.key?.measure);
      }
    }
  }

  private updateSelectedMeasures(measure: Measure) {
    const index: number = this.selectedMeasures.indexOf(measure);
    index == -1 ? this.selectedMeasures.push(measure) : this.selectedMeasures.splice(index, 1);
  }

  private openConfirmDialog(event): void {
    this.measureId = event.row.data.measure.id || event.row.data.id;
    this.confirmPopup.instance.show();
  }

  public onClosePopup(): void {
    this.measureDetailsPopup.instance.hide();
  }

  public openMeasureDialog(): void {
    this.measureDetailsPopup.instance.show();
  }

  private getLookupColumn(key: string) {
    if (key === 'responsible') {
      return { valueExpr: 'id', displayExpr: this.dataService.getResponsibleFullName, dataSource: this.responsibles };
    }
  }


  private getVisibleIndex(key: string): number {
    switch (key) {
      case 'title':
        return 0;
      case 'status':
        return 1;
      case 'endDate':
        return 2;
      case 'responsible':
        return 3;
      case 'currentGrade':
        return 4;
      case 'creationDate':
        return 5;
    }
  }

  public removeRow(): void {
    this.subscriptions.push(this.persistenceService.addObjectForDelete(this.measureId).subscribe(() => {
      const index = this.measureGrid.gridData.findIndex((element) => element.measure.id == this.measureId);
      this.measureGrid.gridData.splice(index, 1);
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
    }, (error) => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
    this.confirmPopup.instance.hide();
  }

  public abortDelete(): void {
    this.confirmPopup.instance.hide();
  }
}