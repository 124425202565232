import { Injectable } from '@angular/core';

import { Column } from 'devextreme/ui/data_grid';

import { DataDefinitionOptions } from '../../models/customer-space.model';

import { Measure, MeasureTask } from '../../models/measure.model';

@Injectable({
  providedIn: 'root'
})
export class MeasureService {
  public measures: Measure[];
  public selectedMeasures: Measure[];
  public cachedColumnsResponse: Column[];

  public degree = [{ id: 0, value: "0%" }, { id: 25, value: "25%" }, { id: 50, value: "50%" }, { id: 75, value: "75%" }, { id: 100, value: "100%" }];

  public maturityLevel: DataDefinitionOptions[] = [
    { name: 'HG1', value: 'HG1' },
    { name: 'HG2', value: 'HG2' },
    { name: 'HG3', value: 'HG3' },
    { name: 'HG4', value: 'HG4' },
    { name: 'HG5', value: 'HG5' }
  ];

  public prepareMeasureTaskPersistObject(measureTask): MeasureTask {
    const query: MeasureTask = new MeasureTask(null);
    query.ident = measureTask.ident;
    query.label = measureTask.label;
    query.degreeOfImplementation = measureTask.degreeOfImplementation;
    query.efficiency = measureTask.efficiency;
    query.notes = measureTask.notes;
    query.measure = measureTask.measure;
    if (!measureTask.targetDate) {
      query.targetDate = measureTask.startDate;
    } else {
      query.targetDate = measureTask.targetDate;
    }
    query.startDate = measureTask.startDate;
    query.responsible = measureTask.responsible;
    query.targetMaturityLevel = measureTask.targetMaturityLevel;
    query.collaborators = measureTask.collaborators;
    query.totalTimeRequired = measureTask.totalTimeRequired;
    query.totalTimeExecuted = measureTask.totalTimeExecuted;
    query.meta = measureTask.meta;

    return query;
  }

  constructor() { }
}