import { Component, OnInit, ViewChild } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';
import { SharedDataService } from '@wissenswerft/organizational-structure';
import { Column } from 'devextreme/ui/data_grid';
import { Measure, MeasureTask } from '../../../models/measure.model';
import { SubProjectService } from '../sub-project.service';
import { DataService } from '../../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DxDataGridComponent, DxGalleryComponent } from 'devextreme-angular';
import { GridComponent } from '@wissenswerft/ww-library';
import { ProjectState } from '../../../view-models/sub-project.view-model';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'ubt-sub-project-detail',
  templateUrl: './sub-project-detail.component.html',
  styleUrls: ['./sub-project-detail.component.scss']
})
export class SubProjectDetailComponent implements OnInit {
  @ViewChild(DxGalleryComponent, { static: false }) gallery: DxGalleryComponent;
  @ViewChild('subProjectReport', { static: false }) subProjectReport: DxDataGridComponent;
  public showLoader = false;
  private measureTasks = [];
  private subProjectIndex: number;
  public subProjectTitle: string;
  public subprojectsId = [];
  public columnsHeader: Column[] = [
    {
      dataField: 'measureTitle',
      caption: this.dataService.res('Ubt-CreateMeasureTask-Measure'),
      width: "40%"
    },
    {
      dataField: 'progressPercentage',
      cellTemplate: "progressCellTemplate",
      caption: this.dataService.res('Ubt-MeasureDetail-Chart-Progress'),
      width: "15%"
    },
    {
      dataField: 'maturityLevel',
      caption: this.dataService.res('Ubt-MeasureDetail-Maturity-Level'),
      width: "15%"
    },

    {
      dataField: 'totalTime',
      caption: this.dataService.res('Ubt-CreateMeasureTask-Total-Time'),
      width: "15%",
      customizeText: text => {
        return text.valueText ? text.valueText + ' h' : 0 + 'h';
      }
    },
    {
      dataField: 'executedTime',
      width: "15%",
      caption: this.dataService.res('Ubt-CreateMeasureTask-Executed-Time'),
      customizeText: text => {
        return text.valueText ? text.valueText + ' h' : 0 + 'h';
      }
    }
  ];

  constructor(public subProjectService: SubProjectService,
    private router: Router,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private sharedDataService: SharedDataService, public dataService: DataService) {
    this.subProjectIndex = this.activatedRoute.snapshot.params.index;
  }

  public customizeTooltip = (pointsInfo) => ({ text: `${parseInt(pointsInfo.originalValue)}%` });


  ngOnInit(): void {
    this.showLoader = true;
    this.sharedDataService.getDefinitionAndData<Measure[]>(TypeKey.measure).subscribe(meausre => {
      this.sharedDataService.getDefinitionAndData<MeasureTask[]>(TypeKey.measureTask).subscribe((data) => {
        this.measureTasks = data[1];
      }, error => {
      }, () => {
        this.measureTasks = this.measureTasks.reduce((element, measure) => {
          (element[measure.measure] = element[measure.measure] || []).push(measure);
          return element;
        }, {});
        this.subProjectService.subProjectReports = meausre[1].reduce( (element, measure) => {
          element[measure.subProject.id] = element[measure.subProject.id] || [];
          const report = new SubProjectReport(null);
          report.implementationStatus = measure.subProject.implementationStatus;
          report.subProjectLabel = measure.subProject.label;
          report.measureId = measure.id;
          report.measureTitle = measure.title;
          report.maturityLevel = measure.measureProgress[measure.measureProgress.length - 1]?.maturityLevel || 'N/A';
          report.progressPercentage = measure.measureProgress[measure.measureProgress.length - 1]?.progressPercentage;
          report.decision = measure.measureProgress[measure.measureProgress.length - 1]?.decision;
          report.nextSteps = measure.measureProgress[measure.measureProgress.length - 1]?.nextSteps;
          report.progress = measure.measureProgress[measure.measureProgress.length - 1]?.progress;
          report.riskAndChance = measure.measureProgress[measure.measureProgress.length - 1]?.riskAndChance;
          Object.entries(this.measureTasks).forEach(measureTask => {
            if (measureTask[0] === measure.id.toString()) {
              const currentMeasureTask: any = measureTask[1]
              currentMeasureTask.forEach(measureTaskData => {
                report.totalTime = report.totalTime + measureTaskData.totalTimeRequired || report.totalTime + 0;
                report.executedTime = report.executedTime + measureTaskData.totalTimeExecuted || report.executedTime + 0;
              });
            }
          });
          element[measure.subProject.id].push(report);
          return element;
        }, {});
        this.subProjectService.subProjectReports = Object.entries(this.subProjectService.subProjectReports);
        if (this.appService.reportIndex) {
          this.gallery.instance.goToItem(this.appService.reportIndex, false);
          this.appService.reportIndex = undefined;
        } else {
          this.gallery.instance.goToItem(this.subProjectIndex, false);
        }
        this.showLoader = false;
      })
    }, error => {
      this.showLoader = false;
      console.error(error);
    }, () => {
    });

  }

  public backToSubProjectList(): void {
    this.router.navigateByUrl('subProject');
  }

  public onCellPrepared = (e): void => {
    if (e.column.dataField !== 'measureTitle') {
      e.cellElement.style.textAlignLast = 'center';
    }
    if (e) {
      e.cellElement.style.verticalAlign = "middle";
    }
  }

  public goToNextItem(): void {
    this.gallery.instance.nextItem(true);

  }

  public goToPreviousItem(): void {
    this.gallery.instance.prevItem(true);
  }

  public prepareContextMenu(event): void {
    if (event.row.rowType === "data") {
      event.items = [{
        text: this.dataService.res('Ubt-measure-Go-To') + ' ' + event.row.data?.measureTitle,
        icon: 'assets/images/go-to-file.svg',
        onItemClick: (e) => {
          this.appService.reportIndex = this.gallery.selectedIndex;
          this.router.navigate(['measureDetail', 'subProject', event.row.data?.measureId]);
        }
      }];
    }
  }

}

export class SubProjectReport {
  measureId?: number;
  measureTitle?: string;
  progress?: string;
  nextSteps?: string;
  riskAndChance?: string;
  decision?: string;
  progressPercentage?: number;
  maturityLevel?: string;
  totalTime?: number = 0;
  executedTime?: number = 0;
  implementationStatus?: string;
  subProjectLabel?: string;

  constructor(data: SubProjectReport) {
    Object.assign(this, data);
  }
}

export enum StatePath {
  GRAY = "assets/images/gray.svg",
  GREEN = "assets/images/green.svg",
  YELLOW = "assets/images/yellow.svg",
  RED = "assets/images/red.svg"
}



