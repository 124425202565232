import { Injectable } from '@angular/core';
import { StaffMember } from '@wissenswerft/organizational-structure';

@Injectable({
  providedIn: 'root'
})
export class StaffMembersService {
  
  constructor() { }

  public prepareMembersPersistObject(staffMember: StaffMember): StaffMember {
    const query: StaffMember = new StaffMember(null);
    query.ident = staffMember.ident;
    query.label = staffMember.label;
    query.firstname = staffMember.firstname;
    query.lastname = staffMember.lastname;
    query.eMail = staffMember.eMail;
    query.responsible = staffMember.responsible;
    query.role = staffMember.role;
    return query;
  }

}