import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '@wissenswerft/core/authentication';

import { LoginComponent } from '@wissenswerft/core/authentication';

import { AppComponent } from './app.component';

import { MeasureDetailComponent } from './core/customer-space/measure-list/measure-detail/measure-detail.component';

import { MeasureListComponent } from './core/customer-space/measure-list/measure-list.component';

import { MeasureTaskComponent } from './core/customer-space/measure-task/measure-task.component';

import { HomeComponent } from './core/home/home.component';

import { TagComponent } from './core/customer-space/tag/tag.component';

import { MeasureTypeComponent } from './core/customer-space/measure-type/measure-type.component';

import { SubProjectComponent } from './core/customer-space/sub-project/sub-project.component';

import { StaffMembersComponent } from './core/customer-space/staff-members/staff-members.component';

import { DocumentComponent } from '@wissenswerft/organizational-structure';

import { RolesComponent } from './core/customer-space/roles/roles.component';

import { ReportsComponent } from './core/customer-space/reports/reports.component';

import { MeasureTaskGanttComponent } from './core/customer-space/measure-task-gantt/measure-task-gantt.component';

import { SubProjectDetailComponent } from './core/customer-space/sub-project/sub-project-detail/sub-project-detail.component';

const routes: Routes = [
  {
    path: 'app',
    component: AppComponent, canActivate: [AuthenticationGuard]
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'home',
    component: HomeComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/home/home.module')
      .then(module => module.HomeModule)
  },
  {
    path: 'measures',
    component: MeasureListComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/measure-list/measure-list.module')
      .then(module => module.MeasureListModule)
  },
  {
    path: 'measureDetail/:from/:id',
    component: MeasureDetailComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/measure-list/measure-detail/measure-detail.module')
      .then(module => module.MeasureDetailModule)
  },
  {
    path: 'documents',
    component: DocumentComponent, canActivate: [AuthenticationGuard],
  },
  {
    path: 'tasks',
    component: MeasureTaskComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/measure-task/measure-task.module')
      .then(module => module.MeasureTaskModule)
  },
  {
    path: 'reports',
    component: ReportsComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/reports/reports.module')
      .then(module => module.ReportsModule)
  },
  {
    path: 'gantt',
    component: MeasureTaskGanttComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/measure-task-gantt/measure-task-gantt.module')
      .then(module => module.MeasureTaskGanttModule)
  },
  {
    path: 'staffMembers',
    component: StaffMembersComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/staff-members/staff-members.module')
      .then(module => module.StaffMembersModule)
  },
  {
    path: 'roles',
    component: RolesComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/roles/roles.module')
      .then(module => module.RolesModule)
  },
  {
    path: 'measureType',
    component: MeasureTypeComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/shared/configuration.module')
      .then(module => module.ConfigurationModule)
  },
  {
    path: 'tag',
    component: TagComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/shared/configuration.module')
      .then(module => module.ConfigurationModule)
  },
  {
    path: 'subProject',
    component: SubProjectComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/sub-project/sub-project.module')
      .then(module => module.SubProjectModule)
  },
  {
    path: 'subProjectDetail/:index',
    component: SubProjectDetailComponent, canActivate: [AuthenticationGuard],
    loadChildren: () => import('./core/customer-space/sub-project/sub-project-detail/sub-project-detail.module')
      .then(module => module.SubProjectDetailModule)
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthenticationGuard]
})
export class AppRoutingModule { }