import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConvertHelper, TypeKey } from '@wissenswerft/core/data';
import { DataDefinitionViewModel, MeasureProgress, SharedDataService } from '@wissenswerft/organizational-structure';
import { Column } from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';
import { Measure } from '../../models/measure.model';
import { map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppService } from '../../services/app.service';
import { GridComponent } from '@wissenswerft/ww-library';

@Component({
  selector: 'ubt-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  @ViewChild('allReportsGrid') allReportsGrid: GridComponent;
  public allReports: CustomReport[] = [];

  public columns: Column[] = [];

  public showAllReports = true;

  public convertHelper = ConvertHelper;

  public showLoader = false;

  private measures: Measure[] = [];

  private subscriptions: Subscription[] = [];

  public tabs = [
    {
      id: 0,
      text: this.dataService.res('Ubt-Report-All'),
      icon: 'assets/images/calendar.svg',
    },
    {
      id: 1,
      text: this.dataService.res('Ubt-Report-Actual'),
      icon: 'assets/images/measure.svg',
      content: 'Comment tab content',
    }
  ];

  constructor(private sharedDataService: SharedDataService, public dataService: DataService, public appService: AppService, private router: Router) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<Measure[]>(TypeKey.measure).pipe(switchMap(measures => {
      return this.sharedDataService.getDefinitionAndData<MeasureProgress[]>(TypeKey.measureProgress)
        .pipe(map(measureProgress => ({ measures, measureProgress })));
    }))
      .subscribe(({ measures, measureProgress }) => {
        this.measures = measures[1];
        const measureProgressDefinitions = measureProgress[0];
        const measureProgressDefinitionVM = new DataDefinitionViewModel(measureProgressDefinitions, REPORT_VISIBLE_PROPERTIES);
        const properties = measureProgressDefinitionVM.properties;
        for (const key in properties) {
          if (properties[key] && properties[key].key !== 'ident') {
            const property = properties[key];
            this.columns.push({
              dataField: property.key,
              caption: property.label,
              visibleIndex: this.setColumnIndex(property.key),
              visible: property.visible,
              dataType: this.dataService.getDataType(property.type),
              width: this.getColumnWidh(property.key),
              alignment: property.key === 'progressPercentage' ? 'center' : 'left',
              customizeText: text => {
                if (property.key === 'progressPercentage') {
                  return text.valueText ? text.valueText + '%' : 0 + '%';
                }
                else {
                  return text.valueText;
                }
              }
            });
          }
        }
        this.prepareExtraColumns();

        this.prepareReports(false);

      }, error => {
        console.error(error);
        this.showLoader = false;
      }, () => {
        this.showLoader = false;

      }));
  }

  private prepareExtraColumns(): void {
    this.columns.push(
      {
        dataField: "status",
        caption: "Status",
        visible: true,
        dataType: "string",
        alignment: 'left',
        calculateCellValue: rowData => {
          if (!rowData.progressPercentage) {
            rowData.progressPercentage = 0;
          }
          return rowData.progressPercentage + '% | ' + rowData.maturityLevel + ' | ' + rowData.date
        }
      },
      {
        dataField: "measure",
        caption: (sessionStorage.getItem('fixedCulture') === 'en') ? "Measure" : "Maßnahme",
        visible: true,
        dataType: "string",
        alignment: 'center',
        groupIndex: 0
      }, {
      dataField: "subProject",
      caption: (sessionStorage.getItem('fixedCulture') === 'en') ? "Sub Project" : "Teilprojekt",
      visible: true,
      dataType: "string",
      alignment: 'center',
      groupIndex: 1
    },
      {
        type: "buttons",
        caption: '',
        minWidth: 70,
        dataField: 'edit',
        alignment: 'center',
        buttons: [{
          icon: 'edit',
          onClick: (e) => { this.openMeasureDetail(e); }
        }]
      });
  }

  private setColumnIndex = (property: string): number => {
    if (property === 'progress') {
      return 1;
    } else if (property === 'nextSteps') {
      return 2;
    } else if (property === 'status') {
      return 3;
    }
  }

  cellPrepared(event) {
    if (event.column.dataField === "progress" || event.column.dataField === "nextSteps"
      || event.column.dataField === "riskAndChance" || event.column.dataField === "decision") {
      event.cellElement.style.height = 100
      event.cellElement.style.whiteSpace = "normal"
      event.cellElement.style.overflowWrap = 'break-word'
    }
  }

  private getColumnWidh(key: string): string {
    switch (key) {
      case 'progress':
        return '20%';
      case 'nextSteps':
        return '20%';
      case 'riskAndChance':
        return '20%';
      case 'decision':
        return '20%';
      case 'opportunities':
        return '20%';
      default:
        return 'auto';
    }
  }

  public openTab = (event): void => {
    switch (event.itemData.id) {
      case 0:
        this.prepareReports(false);
        break;
      case 1:
        this.prepareReports(true);
        break;
    }
  }

  private prepareReports(loadLatestReports: boolean): void {
    this.allReports = [];
    this.measures.forEach(item => {
      if (item.measureProgress.length > 0) {
        if (loadLatestReports === false) {
          item.measureProgress.forEach(meausreProgress => {
            const progress: CustomReport = new CustomReport();
            const currentState: ReportStatus = new ReportStatus();
            progress.measure = item.title;
            progress.measureId = item.id;
            progress.subProject = item.subProject.ident;
            progress.nextSteps = meausreProgress.nextSteps;
            progress.progress = meausreProgress.progress;
            progress.decision = meausreProgress.decision;
            progress.opportunities = meausreProgress.opportunities;
            progress.riskAndChance = meausreProgress.riskAndChance;
            progress.progressPercentage = meausreProgress.progressPercentage;
            progress.maturityLevel = meausreProgress.maturityLevel;
            progress.date = this.convertHelper.toDate(meausreProgress?.date.toString());
            currentState.date = meausreProgress.date;
            currentState.maturity = meausreProgress.maturityLevel;
            currentState.progress = meausreProgress.progressPercentage;
            progress.status = currentState;
            this.allReports.push(progress)
          });
        } else {
          const progress: CustomReport = new CustomReport();
          const currentState: ReportStatus = new ReportStatus();
          progress.measure = item.title;
          progress.measureId = item.id;
          progress.subProject = item.subProject.ident;
          item.measureProgress.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());
          item.measureProgress.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());
          progress.nextSteps = item.measureProgress[item.measureProgress.length - 1].nextSteps;
          progress.progress = item.measureProgress[item.measureProgress.length - 1].progress;
          progress.decision = item.measureProgress[item.measureProgress.length - 1].decision;
          progress.riskAndChance = item.measureProgress[item.measureProgress.length - 1].riskAndChance;
          progress.opportunities = item.measureProgress[item.measureProgress.length - 1].opportunities;
          progress.progressPercentage = item.measureProgress[item.measureProgress.length - 1].progressPercentage;
          progress.date = this.convertHelper.toDate(item.measureProgress[item.measureProgress.length - 1]?.date.toString());
          progress.maturityLevel = item.measureProgress[item.measureProgress.length - 1].maturityLevel;
          this.allReports.push(progress);
        }
      }
    });

  }

  public openMeasureDetail(event): void {
    this.dataService.appService.showMeasureIcon = true;
    this.router.navigate(['measureDetail', 'reports', event.row.data.measureId]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe() });
  }

}

export const REPORT_VISIBLE_PROPERTIES = ["measure", "progress", "nextSteps", "riskAndChance", "decision", "status"];

export class CustomReport {
  measureId: number;
  measure: string;
  subProject: string;
  nextSteps: string;
  opportunities: string;
  progress: string;
  decision: string;
  maturityLevel: string
  progressPercentage: number
  date: string;
  riskAndChance: string;
  status: ReportStatus;
}

export class ReportStatus {
  date: Date;
  progress: number;
  maturity: string;
}
