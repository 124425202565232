import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { PersistenceService, TypeKey } from '@wissenswerft/core/data';

import { DataDefinitionViewModel, Role, SharedDataService } from '@wissenswerft/organizational-structure';

import { GridComponent, ToastType } from '@wissenswerft/ww-library';

import { Column } from 'devextreme/ui/data_grid';

import { Subscription } from 'rxjs';
import { AppService } from '../../services/app.service';

import { DataService } from '../../services/data.service';

@Component({
  selector: 'ubt-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit, OnDestroy {

  @ViewChild('rolesGrid') rolesGrid: GridComponent;

  public availableRoles: Role[] = [];

  private readonly VISIBLE_PROPERTIES = ['name'];

  public pluralTitle: string;

  public isUpdating = false;

  public title: string;

  public showLoader = false;

  public columnsHeader: Column[] = [];

  private subscriptions: Subscription[] = [];

  constructor(public dataService: DataService,

    private sharedDataService: SharedDataService,

    private persistenceService: PersistenceService,
    
     public appService: AppService) { }

  ngOnInit(): void {

    this.showLoader = true;

    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<Role[]>(TypeKey.role).subscribe(data => {
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.role] = definitions;
      this.title = definitions.name;
      this.pluralTitle = definitions.namePlural;
      this.availableRoles = data[1];
      const roleDefinitionVM = new DataDefinitionViewModel(definitions, this.VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.role].roleDefinitionVM = roleDefinitionVM;
      const properties = roleDefinitionVM.properties;
      for (const key in properties) {
        if (properties[key]) {
          const property = properties[key];
          this.columnsHeader.push({
            dataField: property.key,
            caption: property.label,
            visible: property?.visible,
            dataType: this.dataService.getDataType(property.type),
          });
        }
      }
    }, error => {
      console.error(error);
      this.showLoader = false;
    }, () => {
      this.showLoader = false;
    }));

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((role: Role) => {
      this.availableRoles.pop();
      this.availableRoles.push(role);
    }));
  }

  public assignNewRole = (): void => {
    this.isUpdating = false;
    this.rolesGrid.addRow();
  }

  public updateRow(e) {
    this.isUpdating = true;
    const roleId = e.data.id;
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.role].roleDefinitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.role].roleDefinitionVM.listProperties;
    const object = this.sharedDataService.createPersistObject(e.data, multilingualProperties, listProperties);
    this.subscriptions.push(this.persistenceService.addObjectForUpdate(roleId, object).subscribe(() => {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public saveRow(e) {
    if ((e.changes[0].data.name) && (this.isUpdating === false)) {
      const role = new Role();
      role.name = e.changes[0].data.name;
      const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.role].roleDefinitionVM.multilingualProperties;
      const listProperties = this.sharedDataService.definitionsVM[TypeKey.role].roleDefinitionVM.listProperties;
      const query = this.sharedDataService.createPersistObject(role, multilingualProperties, listProperties);
      this.persistenceService.addObjectForInsert(TypeKey.role, query).subscribe(role => {
        this.sharedDataService.updateGridData(role);
        this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
      }, error => {
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      });
    } else {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Empty"), type: ToastType.INFO });
      e.cancel = true;
    }
  }

  public removeRow(event): void {
    this.isUpdating = true;
    const roleId = event.data.id;
    this.subscriptions.push(this.persistenceService.addObjectForDelete(roleId).subscribe(() => {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe() });
  }

}
