import { NgModule, APP_INITIALIZER } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { CoreAuthenticationModule } from '@wissenswerft/core/authentication';

import { CoreConfigurationModule, CoreConfigService, BACKEND_SYSTEM } from '@wissenswerft/core/configuration';

import { DxContextMenuModule, DxDrawerModule, DxScrollViewModule, DxSwitchModule, DxToolbarModule, DxTooltipModule, DxTreeViewModule } from 'devextreme-angular';

import { DataService } from './core/services/data.service';

import { ScreenService, SharedDataService } from '@wissenswerft/organizational-structure';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

import { WwLibraryModule } from '@wissenswerft/ww-library';

import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    WwLibraryModule,
    CommonModule,
    CoreAuthenticationModule,
    CoreConfigurationModule,
    DxTooltipModule,
    DxToolbarModule,
    DxDrawerModule,
    DxTreeViewModule,
    DxContextMenuModule,
    DxSwitchModule,
    DxScrollViewModule,
    MatIconModule,
  ],
  providers: [
    CoreConfigService,
    SharedDataService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigurationFactory,
      deps: [CoreConfigService, DataService],
      multi: true
    },
    ScreenService,
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function ConfigurationFactory(globalConfig: CoreConfigService) {
  return () => {
    return new Promise((resolve) => {
      globalConfig.loadConfiguration(environment.API_URL, BACKEND_SYSTEM.SPACEONE).then((config) => {
        resolve(config);
      });
    });
  };
}