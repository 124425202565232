import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Measure } from '../models/measure.model';

import { AppService } from './app.service';

import { ProfileInfo } from 'libs/core/authentication/src/lib/models/profile-info.model';

import { ResourceManager } from '@wissenswerft/core/resources';
import { DxNumberBoxComponent } from 'devextreme-angular';
import { ValidationStatus } from '@wissenswerft/ww-library';

@Injectable()
export class DataService {

  private measureDetailSubject = new BehaviorSubject<Measure>(null);

  public cachedResponsiblesResponse: ProfileInfo[];

  public cachedProfileAvatar : string[] = [];

  public res = ResourceManager.getResources("ubt");

  constructor(public appService: AppService) { }

  public getMeasureDetail(): Observable<Measure> {
    return this.measureDetailSubject.asObservable();
  }

  public sendMeasureDetail(measureAssessment: Measure) {
    this.measureDetailSubject.next(measureAssessment);
  }

  public checkGridGroupByColumns(key: string, groupByColumnIndex) {
    if (localStorage.getItem(key)) {
      const gridState = JSON.parse(localStorage.getItem(key));
      gridState.columns.map(item => {
        if (item.groupIndex) {
          groupByColumnIndex[item.dataField] = item.groupIndex;
          return groupByColumnIndex;
        }
      })
    }
  }

  public getDataType(dataType) {
    switch (dataType) {
      case 'TEXT':
        return 'string';
      case 'NUMBER':
        return 'number';
      case 'LOCALDATE':
        return 'date';
      case 'BOOLEAN':
        return 'boolean';
      default:
        return 'string';
    }
  }

  public validateNumberField(field: DxNumberBoxComponent, minVal: number, maxVal: number) {
    if (field.value) {
      if ((field.value >= minVal) && (field.value <= maxVal)) {
        field.validationStatus = ValidationStatus.VALID;
      } else {
        field.validationStatus = ValidationStatus.INVALID;
      }
    }
  }

  public getResponsibleFullName(account: ProfileInfo): string {
    return account ? account?.lastname + ', ' + account?.firstname : '';
  }

  public customizePercentageText(event) {
    return event ? event + '%' : 0+'%';
  }
}