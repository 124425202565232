import { Injectable } from '@angular/core';
import { Event, SubProject } from '@wissenswerft/organizational-structure';
import { SpaceOneCoreDataService, TypeKey } from '@wissenswerft/core/data';
import { SubProjectViewModel } from '../../view-models/sub-project.view-model';

@Injectable({
  providedIn: 'root'
})
export class SubProjectService {
  public events: Event[];
  public subProjectReports;
  constructor(private coreDataService: SpaceOneCoreDataService) { }

  public prepareSubProjectPersistObject(subProject: SubProjectViewModel): SubProject {
    const query: SubProject = new SubProject(null);
    query.label = subProject.label;
    query.ident = subProject.ident.replace(/\s/g, '');
    query.event = subProject.event;
    query.responsible = subProject.responsible;
    query.responsibleSupport = subProject.responsibleSupport;
    query.highlights = subProject.highlights;
    query.lowlights = subProject.lowlights;

    return query;
  }

  public prepareEvents(): void {
    this.coreDataService.getItemsByName<Event[]>(TypeKey.event).subscribe({
      next: events => {
        this.events = events;
      }
    });
  }
}