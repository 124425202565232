import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { PersistenceService, SpaceOneCoreDataService, TypeKey } from '@wissenswerft/core/data';
import { DataDefinitionViewModel, SharedDataService, SubProject } from '@wissenswerft/organizational-structure';
import { ToastType, WindowLayoutComponent } from '@wissenswerft/ww-library';
import { DxDataGridComponent, DxFormComponent, DxPopupComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { DataService } from '../../services/data.service';
import { SubProjectViewModel, VISIBLE_PROPERTIES, ProjectStateIcons, ProjectState } from '../../view-models/sub-project.view-model';
import { SubProjectService } from './sub-project.service';

@Component({
  selector: 'ubt-sub-project',
  templateUrl: './sub-project.component.html',
  styleUrls: ['./sub-project.component.scss']
})
export class SubProjectComponent implements OnInit, OnDestroy {
  @ViewChild('subProjectGrid') subProjectGrid: DxDataGridComponent;
  @ViewChild('windowLayout') windowLayout: WindowLayoutComponent;
  @ViewChild('createSubProjectPopup') createSubProjectPopup: DxPopupComponent;
  @ViewChild('event') eventSelectBox: DxSelectBoxComponent;
  @ViewChild('responsible') responsibleSelectBox: DxSelectBoxComponent;
  @ViewChild('support') supportSelectBox: DxSelectBoxComponent;
  @ViewChild('form') form: DxFormComponent;
  @ViewChild('confirmSubProjectPopup') confirmSubProjectPopup: DxPopupComponent
  public subProject: SubProject = new SubProject(null);
  public title: string;
  public pluralTitle: string;
  public subProjects: SubProjectViewModel[] = [];
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];
  public responsibles: ProfileInfo[] = [];
  private subProjectId: number;
  private subProjectIndex: number;
  public showLoader = false;
  public readonly subProjectStates = [
    {
      text: this.dataService.res('Ubt-SubProject-Create-State-Countless'),
      value: ProjectState.COUNTMEASURES
    },
    {
      text: this.dataService.res('Ubt-SubProject-Create-State-Risk'),
      value: ProjectState.NORISK
    },
    {
      text: this.dataService.res('Ubt-SubProject-Create-State-Bsceduel'),
      value: ProjectState.BEHINDSCHEDUEL
    },
    {
      text: this.dataService.res('Ubt-SubProject-Create-State-Critical'),
      value: ProjectState.CRITICAL
    }
  ]
  public isUpdating = false;
  public submitButtonText = 'Create';
  public cancelButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Cancel'),
    onClick: () => this.clearWindow()
  };

  constructor(
    private sharedDataService: SharedDataService,
    private persistenceService: PersistenceService,
    public dataService: DataService,
    private router: Router,
    public subProjectService: SubProjectService,
    private coreDataService: SpaceOneCoreDataService,
    public appService: AppService
  ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.subProject = new SubProject(null);
    this.subProjectService.prepareEvents();
    if (this.sharedDataService.definitionsVM[TypeKey.subProject]) {
      this.title = this.sharedDataService.definitionsVM[TypeKey.subProject].name;
    }

    this.coreDataService.getAllAccounts<ProfileInfo[]>().pipe(switchMap(responsibles => {
      return this.sharedDataService.getDefinitionAndData<SubProject[]>(TypeKey.subProject)
        .pipe(map(data => ({ responsibles, data })));
    })).subscribe(({ responsibles, data }) => {
      this.responsibles = responsibles;
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.subProject] = definitions;
      this.title = definitions.name;
      this.pluralTitle = definitions.namePlural;
      const subProjects = data[1];
      const subProjectDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.subProject].subProjectDefinitionVM = subProjectDefinitionVM;
      const properties = subProjectDefinitionVM.properties;
      subProjects.forEach((subProject: SubProject) => {
        const subProjectViewModel = new SubProjectViewModel(subProject);
        this.subProjects.push(subProjectViewModel);
      });
    }, error => {
      console.error(error);
      this.showLoader = false;
    }, () => {
      this.showLoader = false;
    });

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((subProject: SubProjectViewModel) => {
      this.subProjects.push(subProject);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
  }

  public openEditSubProject= (e) =>{
    this.isUpdating = true;
    this.subProject = e.data;
    this.submitButtonText = 'Update';
    this.createSubProjectPopup.title = this.dataService.res('Ubt-SubProject-Update-SubProject');
    this.createSubProjectPopup.instance.show();
  }

  public openSubProjectDetail = (e): void => {
   this.router.navigate(['subProjectDetail', e.rowIndex]);
  }

  public openConfirmTaskDialog = (event) => {
    this.subProjectId = event.data.id || event.key;
    this.subProjectIndex = event.rowIndex;
    this.confirmSubProjectPopup.instance.show();
  }

  public removeRow = (event): void => {
    this.persistenceService.addObjectForDelete(this.subProjectId).subscribe(() => {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
      this.subProjects.splice(this.subProjectIndex, 1);
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }, () => {
      this.confirmSubProjectPopup.instance.hide();
    });
  }

  public setResponsible(e) {
    return e ? e.id : null;
  }

  public onResponsibleChange(e): void {
    this.subProject.responsible = this.responsibles[this.responsibles.findIndex(x => x.id == e.value)];
  }

  public onResponsibleSupportChange(e): void {
    this.subProject.responsibleSupport = this.responsibles[this.responsibles.findIndex(x => x.id == e.value)];
  }

  public rowUpdated(event): void {
    if (this.subProject?.label !== '' && this.subProject?.ident !== '') {
      const subProjectId = this.subProject.id;
      const query: SubProject = new SubProject(null);
      query.label = this.subProject.label;
      query.ident = query.label;
      query.responsible = this.subProject.responsible;
      query.responsibleSupport = this.subProject.responsibleSupport;
      query.highlights = this.subProject.highlights;
      query.lowlights = this.subProject.lowlights;
      query.implementationStatus = this.subProject.implementationStatus;
      const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.subProject].subProjectDefinitionVM.multilingualProperties;
      const listProperties = this.sharedDataService.definitionsVM[TypeKey.subProject].subProjectDefinitionVM.listProperties;
      const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
      this.persistenceService.addObjectForUpdate(subProjectId, object).subscribe((data) => {
        this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
      }, error => {
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      }, () => {
        this.createSubProjectPopup.instance.hide();
      });
    } else {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Empty"), type: ToastType.INFO });
      event.cancel = true;
    }
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'event':
        return { valueExpr: 'id', displayExpr: 'label', dataSource: this.subProjectService.events };
      case 'responsible':
      case 'responsibleSupport':
        return { valueExpr: 'id', displayExpr: this.dataService.getResponsibleFullName, dataSource: this.responsibles };
    }
  }

  public openSubProjectDialog(): void {
    this.isUpdating = false;
    this.subProject = new SubProject(null);
    this.subProject.implementationStatus = ProjectState.COUNTMEASURES;
    this.submitButtonText = 'Create';
    this.createSubProjectPopup.title = this.dataService.res('Ubt-SubProject-Create-SubProject');
    this.createSubProjectPopup.instance.show();
  }

  public abortTaskDelete() {
    this.confirmSubProjectPopup.instance.hide();
  }

  public createSubProject(event): void {
    if (this.isUpdating === false) {
      this.subProject.ident = this.subProject.label;
      this.subProject.responsible = this.responsibleSelectBox.value;
      this.subProject.responsibleSupport = this.supportSelectBox.value;
      this.persistObject().subscribe((data: SubProject) => {
        const subProject = new SubProjectViewModel(data)
        this.sharedDataService.updateGridData(subProject);
        this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
        this.clearWindow();
      }, error => {
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      });
      event.preventDefault();
      this.subProject = new SubProject(null);
    } else {
      this.rowUpdated(event);
      event.preventDefault();
    }
  }

  private persistObject(): Observable<any> {
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.subProject].subProjectDefinitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.subProject].subProjectDefinitionVM.listProperties;
    const query = this.sharedDataService.createPersistObject(this.subProject, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.subProject, query);
  }

  public clearWindow(): void {
    this.createSubProjectPopup.instance.hide();
  }

  public onGridCellPrepared(event): string {
    if (event.column?.dataField === 'implementationStatus') {
      event.cellElement.style.textAlign = "center";
      const value: string = event?.value;
      if (event.rowType === 'data') {
        if (value === ProjectState.NORISK) {
          return event.cellElement.innerHTML = ProjectStateIcons.GREEN;
        } else if (value === ProjectState.BEHINDSCHEDUEL) {
          return event.cellElement.innerHTML = ProjectStateIcons.YELLOW;
        } else if (value === ProjectState.CRITICAL) {
          return event.cellElement.innerHTML = ProjectStateIcons.RED;
        } else if (value === ProjectState.COUNTMEASURES) {
          return event.cellElement.innerHTML = ProjectStateIcons.GRAY;
        } else {
          return event.cellElement.innerHTML = ProjectStateIcons.GRAY;
        }
      }
    }
  }
}
