import { Component, OnDestroy, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { AccessibleScopes, ProfileInfo, UserService } from '@wissenswerft/core/authentication';

import { CoreConfigService } from '@wissenswerft/core/configuration';

import { SpaceOneCoreDataService } from '@wissenswerft/core/data';

import { Subscription } from 'rxjs';

import { DataService } from '../services/data.service';

@Component({
  selector: 'ubt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  public profileInformation: ProfileInfo = new ProfileInfo(null);

  public companyChoices: AccessibleScopes[] = [];

  private subscriptions : Subscription[] = [];

  constructor(
    private router: Router, 

    private coreDataService: SpaceOneCoreDataService,

    private userService: UserService,

    private coreConfigservice: CoreConfigService,

    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.userService.getProfileInformations().subscribe(profileInfo => {
      this.profileInformation = new ProfileInfo(profileInfo);
      this.companyChoices = this.profileInformation.accessibleScopes;
      this.companyChoices.forEach(choice => {
        choice.tileIconUrl = this.coreConfigservice.configuration.WebApi.BaseURL.concat(choice.tileIconUrl);
      });
    }, error => {
      console.error(error);
    }));
  }

  public itemClick(item): void {
    localStorage.setItem('scopeKey', item.text);
    this.coreDataService.prepareTitleScope(item.html);
    this.coreDataService.scope = item.text;
    this.coreDataService.setScope(item.text);
    this.router.navigateByUrl('subProject');
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }
}
