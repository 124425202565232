import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SpaceOneCoreDataService, TypeKey } from '@wissenswerft/core/data';
import { Collaborator, DataDefinitionViewModel, SharedDataService, StaffMember, SubProject } from '@wissenswerft/organizational-structure';
import { DxGanttComponent } from 'devextreme-angular';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Measure, MeasureTask } from '../../models/measure.model';
import { exportGantt } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { DataService } from '../../services/data.service';
import { VISIBLE_PROPERTIES } from '../../view-models/measure-task.view-model';

export enum GanttTaskColor {
  Green = 'Green',
  LightGreen = 'light Green',
  LightBlue = 'light blue',
}
export enum GanttExportMode {
  All = 'all',
  Chart = 'chart',
  TreeList = 'treeList'
}
export enum GanttDateRange {
  All = 'all',
  Visible = 'visible',
  Custom = 'custom'
}
export enum GanttExportDocumentFormat {
  A0 = 'a0',
  A1 = 'a1',
  A2 = 'a2',
  A3 = 'a3',
  A4 = 'a4',
  Auto = 'auto'
}

@Component({
  selector: 'ubt-measure-task-gantt',
  templateUrl: './measure-task-gantt.component.html',
  styleUrls: ['./measure-task-gantt.component.scss']
})
export class MeasureTaskGanttComponent implements OnInit, OnDestroy {
  @ViewChild('gantt') gantt: DxGanttComponent;
  private _collaborators$: Observable<Collaborator>[] = [];
  public exportOptions = {
    hint: 'Export to PDF',
    icon: 'exportpdf',
    stylingMode: 'text',
    onClick: () => this.exportToPDF(),
  };
  public ganttDataSource: IGanttDataSource[] = [];
  public showLoader = false;
  public resources: IResource[] = [];
  public resourceAssignments: ResourceAssignment[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private coreDataService: SpaceOneCoreDataService,
    private sharedDataService: SharedDataService,
    public dataService: DataService
  ) { }

  ngOnInit(): void {

    this.showLoader = true;

    this.subscriptions.push(this.coreDataService.getItemsByName<SubProject[]>(TypeKey.subProject).pipe(switchMap(subProjects => {
      return this.coreDataService.getItemsByName<StaffMember[]>(TypeKey.staffMember).pipe(switchMap(staffmembers => {
        return this.sharedDataService.getDefinitionAndData<MeasureTask[]>(TypeKey.measureTask)
          .pipe(switchMap(measureTasks => {
            measureTasks[1].forEach(task => {
              if (task?.collaborators.length > 0) {
                task.collaborators.forEach(collaborator => {
                  this._collaborators$.push(this.coreDataService.getDynamicContentItemById<Collaborator>(collaborator));
                });
              }
            });
            return this.coreDataService.getItemsByName<Measure[]>(TypeKey.measure)
              .pipe(switchMap(measures => {
                return forkJoin(this._collaborators$).pipe(map(collaborators => ({ subProjects, staffmembers, measureTasks, measures, collaborators })));
              }));

          }));
      }));
    })).subscribe(({ subProjects, staffmembers, measureTasks, measures, collaborators }) => {
      this.sharedDataService.definitionsVM[TypeKey.measureTask] = measureTasks[0];
      const measureTaskDefinitionVM = new DataDefinitionViewModel(measureTasks[0], VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.measureTask].definitionVM = measureTaskDefinitionVM;
      subProjects.forEach((subProject: SubProject) => {
        const subProjectDs: IGanttDataSource = {
          id: subProject.id,
          title: subProject.label,
          parentId: 0,
          start: subProject.meta.created,
          end: subProject.meta.updated,
          color: GanttTaskColor.Green
        };
        this.ganttDataSource.push(subProjectDs);
      });
      measures.forEach((measure: Measure) => {
        const measureDs: IGanttDataSource = {
          id: measure.id,
          parentId: measure.subProject.id,
          title: measure.title,
          start: measure.startDate,
          end: measure.endDate,
          color: GanttTaskColor.LightGreen
        };
        this.ganttDataSource.push(measureDs);
      });
      measureTasks[1].forEach(task => {
        const measureTaskDs: IGanttDataSource = {
          id: task.id,
          title: task.label,
          parentId: (!task.meta.parent) ? task.measure : task.meta.parent,
          isTaskMilestone: (!task.meta.parent) ? true : false,
          start: task?.startDate,
          end: task.targetDate,
          progress: task.degreeOfImplementation ? task.degreeOfImplementation : 0,
          color: GanttTaskColor.LightBlue
        };
        this.ganttDataSource.push(measureTaskDs);
        task.collaborators?.forEach((collaboratorId) => {
          if (collaboratorId) {
            const currentCollaborator = collaborators.find(x => x.id === collaboratorId);
            const currentStaffMember = staffmembers.find(member => member.id === currentCollaborator.name);
            const collaboratorResource: IResource = {
              id: collaboratorId,
              text: currentStaffMember.lastname + ', ' + currentStaffMember.firstname,
            };
            this.resources.push(collaboratorResource);
            const assignments: ResourceAssignment = {
              id: this.resourceAssignments.length != 0 ? this.resourceAssignments.length : 0,
              taskId: task.id,
              resourceId: collaboratorId
            };
            this.resourceAssignments.push(assignments);
          }
        });
      });
    }, error => {
      console.error(error);
      this.showLoader = false;
    }, () => {
      this.ganttDataSource = [...new Map(this.ganttDataSource.map(v => [v.id, v])).values()];
      this.gantt.instance.repaint();
      this.gantt.instance.refresh();
      this.showLoader = false;
      this.gantt.contextMenu.enabled = false;
    }));

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public exportToPDF() {
    exportGantt({
      component: this.gantt.instance,
      createDocumentMethod: (args?: any) => new jsPDF(args),
      format: GanttExportDocumentFormat.Auto,
      landscape: true,
      exportMode: GanttExportMode.All,
      dateRange: GanttDateRange.All,
    }).then((document) => document.save('gantt.pdf'));
  }
}

export interface IGanttDataSource {
  id: number;
  parentId;
  title: string;
  start: Date;
  end: Date;
  progress?: number;
  color: string;
  isTaskMilestone?: boolean;
}

export interface IResource {
  id: number;
  text: string;
}

export class ResourceAssignment {
  id: number;
  taskId: number;
  resourceId: number;
}