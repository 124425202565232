import { MeasureRecommendation } from "@wissenswerft/organizational-structure";
import { MeasureTask } from "../models/measure.model";

export class MeasureTaskViewModel {

    private measureTask: MeasureTask;

    get id(): number { return this.measureTask.id; }

    get ident(): string { return this.measureTask.ident; }

    get label(): string { return this.measureTask.label; }

    get degreeOfImplementation(): number { return this.measureTask.degreeOfImplementation; }

    get notes(): string { return this.measureTask.notes; }

    get measure(): MeasureRecommendation { return this.measureTask.measure; }

    get startDate(): Date { return this.measureTask.startDate; }

    get targetDate(): Date { return this.measureTask.targetDate; }

    get targetMaturityLevel(): string { return this.measureTask.targetMaturityLevel; }

    get parentId(): number {return this.measureTask.parentId;}

    get subParentId(): number {return this.measureTask.parentId;}

    get responsible(): any {
        if (this.measureTask?.responsible) {
            return this.measureTask?.responsible.id || this.measureTask?.responsible;
        }
    }

    get totalTimeRequired(): number {return this.measureTask.totalTimeRequired;}

    get totalTimeExecuted(): number {return this.measureTask.totalTimeExecuted;}

    set ident(ident) { this.measureTask.ident = ident; }

    set label(label) { this.measureTask.label = label; }

    set degreeOfImplementation(degreeOfImplementation: number) { this.measureTask.degreeOfImplementation = degreeOfImplementation }

    set notes(notes: string) { this.measureTask.notes = notes; }

    set measure(measure: MeasureRecommendation) { this.measureTask.measure = measure; }

    set startDate(startDate: Date) { this.measureTask.startDate = startDate; }

    set targetDate(targetDate: Date) { this.measureTask.targetDate = targetDate; }

    set targetMaturityLevel(targetMaturityLevel: string) { this.measureTask.targetMaturityLevel = targetMaturityLevel; }

    set responsible(data) { this.measureTask.responsible = data; }

    set parentId(parentId: number) { this.measureTask.parentId = parentId; }

    set totalTimeRequired(totalTimeRequired: number) { this.measureTask.totalTimeRequired = totalTimeRequired; }

    set totalTimeExecuted(totalTimeExecuted: number) { this.measureTask.totalTimeExecuted = totalTimeExecuted; }

    constructor(data: MeasureTask) {
        this.measureTask = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'label', 'degreeOfImplementation', 'responsible', 'measure', 'targetMaturityLevel', 'startDate'
];