import { EventEmitter, Injectable, Output } from '@angular/core';
import { AccessibleScopes } from '@wissenswerft/core/authentication';
import { Subject } from 'rxjs';
import { IToast } from '@wissenswerft/ww-library';
import { AccessibleScopesViewModel } from '../view-models/customer-space.view-model';
import { ResourceManager } from '@wissenswerft/core/resources';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private notificationSubject: Subject<IToast> = new Subject();
  public notification$ = this.notificationSubject.asObservable();
  private accessibleScopes: AccessibleScopesViewModel;
  public reportIndex : number;
  public showMeasureIcon: boolean = false;
  @Output() selectItem = new EventEmitter();
  public res = ResourceManager.getResources("ubt");

  constructor() {  }

  public callNotification(notification : IToast): void {
    this.notificationSubject.next(notification);
  }

  public getAccessibleScopes(scopeKey, accessibleScopes?: AccessibleScopes[]) {
    if (!this.accessibleScopes) {
      this.accessibleScopes = new AccessibleScopesViewModel(accessibleScopes);
    }
    return this.accessibleScopes.scopeByName[scopeKey];
  }

  public selectTreeItem = (selectedNode : number) => { 
    this.selectItem.emit(selectedNode); 
  }
}