import { DatePipe } from '@angular/common';

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { ProfileInfo, UserService } from '@wissenswerft/core/authentication';

import { ConvertHelper, SpaceOneCoreDataService, ObjectHelper, PersistenceService, TypeKey } from '@wissenswerft/core/data';

import { Collaborator, MeasureProgress, Resource, SharedDataService, StaffMember, SubProject } from '@wissenswerft/organizational-structure';

import { GridComponent, ScrollMode, ToastType, ValidationStatus } from '@wissenswerft/ww-library';

import { DxChartComponent, DxFileUploaderComponent, DxTagBoxComponent, DxNumberBoxComponent, DxPopupComponent, DxSelectBoxComponent, DxTreeListComponent, DxTextBoxComponent, DxFormComponent } from 'devextreme-angular';

import { Column } from 'devextreme/ui/data_grid';

import { Comment } from 'libs/core/comments/src/lib/models/comment.model';

import { forkJoin, Observable, of, Subscription } from 'rxjs';

import { concatMap, map, switchMap } from 'rxjs/operators';

import { DataDefinitionOptions } from '../../../models/customer-space.model';

import { Measure, MeasureDefinition, MeasureTask, PrevDect } from '../../../models/measure.model';

import { DataService } from '../../../services/data.service';

import { DataDefinitionViewModel } from '../../../view-models/customer-space.view-model';

import { MeasureTaskViewModel } from '../../../view-models/measure-task.view-model';

import { MEASURE_TASK_VISIBLE_PROPERTIES, VISIBLE_PROPERTIES } from '../../../view-models/measure.view-model';

import { SubProjectViewModel } from '../../../view-models/sub-project.view-model';

import { MeasureService } from '../measure.service';

import { CollaboratorsComponent } from './collaborators/collaborators.component';

@Component({
  selector: 'ubt-measure-detail',
  templateUrl: './measure-detail.component.html',
  styleUrls: ['./measure-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasureDetailComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('measureTasksGrid', { static: false }) measureTasksGrid: GridComponent;

  @ViewChild('measureDokumenteGrid', { static: false }) measureDokumenteGrid: GridComponent;

  @ViewChild('collaboratorGrid') set content(content: CollaboratorsComponent) {
    if (content) {
      this.collaboratorGrid = content;
      this.collaboratorGrid.collaboratorSaved.subscribe((query) => {
        if (this.isAutoAdjust === true) {
          this.calculateOverallTime();
        }
        this.assignedCollaborators.push(query);
      });
      this.collaboratorGrid.collaboratorEdited.subscribe(() => {
        if (this.isAutoAdjust === true) {
          this.calculateOverallTime();
        }
      });
      this.collaboratorGrid.collaboratorRemoved.subscribe((query) => {
        if (this.isAutoAdjust === true) {
          this.calculateOverallTime();
        }
        if (query) {
          const index = this.assignedCollaborators.findIndex(item => (item.name === query.name && item.timePlanned === query.timePlanned));
          this.assignedCollaborators.splice(index, 1);
        }
      });
    }
  }

  public collaboratorGrid: CollaboratorsComponent

  @ViewChild('addDocumentPopup') addDocumentPopup: DxPopupComponent;

  @ViewChild('addDocumentSelectBox') addDocumentSelectBox: DxSelectBoxComponent;

  @ViewChild('confirmPopup') confirmPopup: DxPopupComponent;

  @ViewChild('confirmTaskPopup') confirmTaskPopup: DxPopupComponent;

  @ViewChild('milestonePopup') milestonePopup: DxPopupComponent;
  @ViewChild('taskPopup') taskPopup: DxPopupComponent;
  @ViewChild('milestoneForm') milestoneForm: DxFormComponent;
  @ViewChild('taskForm') taskForm: DxFormComponent;
  @ViewChild('meausreTaskTree') meausreTaskTree: DxTreeListComponent;

  @ViewChild('progressField') progressField: DxNumberBoxComponent;

  @ViewChild('efficiencyField') efficiencyField: DxNumberBoxComponent;

  @ViewChild('fileUploader') fileUploader: DxFileUploaderComponent;

  @ViewChild('chart') chart: DxChartComponent;

  @ViewChild('staffMembersTagField') staffMembersTagField: DxTagBoxComponent;
  @ViewChild('collaboratorsTotalTimeGrid') collaboratorsTotalTimeGrid: GridComponent;
  @ViewChild('measureTitle') measureTitle: DxTextBoxComponent;
  private measureId;
  public cancelButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Cancel'),
    onClick: () => this.clearWindow()
  };
  public showLoader = false;

  private returnPath: string;

  private currentDate = new Date().toISOString();
  public date;
  public convertHelper = ConvertHelper;

  public isNewTask: boolean;

  public subProjects: SubProjectViewModel[] = [];

  public measure: Measure = new Measure({});

  private latestAssignedParent: number;

  public isNewMeasureTask = false;

  public measureTask: MeasureTask = new MeasureTask(null)

  public lastMeasureProgress: MeasureProgress = new MeasureProgress();

  public allComments: Comment;

  public workOnLastStatus = false;

  public toogleTitleVisible = false;
  public toogleInformations = false;

  public createSecondMeasureTask = false;

  public updateTaskMode = false;

  public currentMeasureIndex: number;

  public navigationMaximumIndex: number;

  public measureComments: Comment[];

  public allowedParentTasks: MeasureTask[] = [];

  private documentId: number;

  public measureDefinitions: Observable<MeasureDefinition[]>;

  public assignedStaffmembers: StaffMember[] = [];

  public measureTaskId: number;

  public tasksColumns: Column[] = [];
  public collaboratorsTimeData: CollaboratorPlannedTime[] = [];

  private subscriptions: Subscription[] = [];

  public assignedResource: Resource = new Resource();

  public documents = [];

  public prevDect = ConvertHelper.ToArray(PrevDect);

  public responsibles: ProfileInfo[] = [];

  private measureTasksDefinition: DataDefinitionViewModel;

  public status = ValidationStatus;

  public isAutoAdjust = false;

  public measureTasks: MeasureTaskViewModel[] = [];
  public scrollMode: ScrollMode = ScrollMode.Standard;
  private assignedCollaborators = [];
  public totalTimePerCollaboratorPerMonth: Map<string, number> = new Map<string, number>();
  private _tasks: MeasureTask[] = [];

  public priorities = [
    { name: this.dataService.res('Ubt-MeasureDetail-Milestone'), value: false, disabled: false },
    { name: this.dataService.res('Ubt-MeasureDetail-Task'), value: true, disabled: false }
  ];

  public showTaskParents: boolean = false;

  public collaboratorsData: number[] = [];

  public statusPercentage: DataDefinitionOptions[] = [
    { name: '0%', value: 0 },
    { name: '20%', value: 20 },
    { name: '40%', value: 40 },
    { name: '60%', value: 60 },
    { name: '80%', value: 80 },
    { name: '100%', value: 100 }
  ];

  public maturityLevel: DataDefinitionOptions[] = [
    { name: 'HG1', value: 'HG1' },
    { name: 'HG2', value: 'HG2' },
    { name: 'HG3', value: 'HG3' },
    { name: 'HG4', value: 'HG4' },
    { name: 'HG5', value: 'HG5' }
  ];

  public maturityLevelCategories: Array<string> = ['HG1', 'HG2', 'HG3', 'HG4', 'HG5'];

  public documentsColumnsHeader = [
    {
      dataField: 'title', dataType: 'string', caption: 'Title', minWidth: 50, width: '80%', visibleIndex: 0
    },
    {
      type: "buttons",
      caption: '',
      minWidth: 70,
      dataField: '',
      visibleIndex: 2,
      buttons: [{
        icon: 'assets/images/save.svg',
        onClick: (e) => { this.downloadDocument(e.row.data.id) }
      }, {
        icon: "trash",
        onClick: (e) => { this.openConfirmDialog(e); }
      }]
    }

  ];
  public collaboratorsTimeColumns: Column[] = [];

  private readonly base64Format: string = "data:image/png;base64,";

  public weeksInterval: number = 1;

  public minorTickInterval: number = 1 / 7;

  public progressChartData: Array<MeasureProgress> = [];

  private _isCalendarWeek: boolean = true;

  private _datepipe: DatePipe = new DatePipe('en-US');
  private _currentMonth: number;
  private _data = [];
  public submitButtonText: string;

  constructor(

    private activatedRoute: ActivatedRoute,

    public dataService: DataService,

    private coreDataService: SpaceOneCoreDataService,

    private sharedDataService: SharedDataService,

    private router: Router,

    private cdr: ChangeDetectorRef,

    private userService: UserService,

    private persistenceService: PersistenceService,

    public measureService: MeasureService
  ) {
    this.measureId = this.activatedRoute.snapshot.params.id;
    this.returnPath = this.activatedRoute.snapshot.params.from;
    this.date = this._datepipe.transform(this.currentDate, 'MM.dd.yyyy');
  }

  ngOnInit(): void {

    this.showLoader = true;

    this.coreDataService.getAllAccounts<ProfileInfo[]>().subscribe(responsibles => {
      this.responsibles = responsibles;
    });

    this.subscriptions.push(this.coreDataService.getDynamicContentItemById<Measure>(this.activatedRoute.snapshot.params.id).subscribe((measure) => {
      if (!this.measureService.measures) {
        this.subscriptions.push(this.sharedDataService.getDefinitionAndData<Measure[]>(TypeKey.measure).subscribe((data) => {
          this.measureService.measures = data[1];
        }, error => {
          console.error(error);
        }, () => {
          this.prepareNavigationIndex();
        }));
      } else {
        this.prepareNavigationIndex();
      }
      this.measure = measure;
      this.prepareAssignedResouce();
      if (this.measure?.measureProgress.length > 0) {
        this.loadMeasureProgress();
      } else {
        this.lastMeasureProgress.progressPercentage = 0;
        this.lastMeasureProgress.maturityLevel = this.maturityLevel[0].name;
      }
      this.cdr.detectChanges();
    }, (error) => {
      this.router.navigateByUrl('measures');
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Item-Not-Found"), type: ToastType.ERROR });
      console.clear();
    }, () => {
      this.showLoader = false;
    }));

    this.subscriptions.push(this.coreDataService.getCommentsByItemId<Comment[]>(this.activatedRoute.snapshot.params.id).subscribe((data) => {
      this.measureComments = data;
      this.measureComments.forEach(element => {
        if (!this.dataService.cachedProfileAvatar[element.author.id]) {
          this.userService.getAvatarById(element.author.id).subscribe(avatar => {
            const imageBase = this.base64Format + btoa(
              new Uint8Array(avatar)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            element.avatar = imageBase;
            this.dataService.cachedProfileAvatar[element.author.id] = imageBase;
          })
        } else {
          element.avatar = this.dataService.cachedProfileAvatar[element.author.id];
        }
      });
    }, (error) => {
      console.clear();
    }));

    this.loadAssignedTasks(false);

    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<SubProject[]>(TypeKey.subProject).subscribe((data) => {
      data[1].map(subProject => {
        this.subProjects.push(new SubProjectViewModel(subProject));
      })
    }));

    this.loadDocuments();

    if (!this.sharedDataService.definitionsVM[TypeKey.resource]) {
      this.subscriptions.push(this.sharedDataService.getDefinitionAndData<Resource>(TypeKey.resource).subscribe(data => {
        const definitions = data[0];
        this.sharedDataService.definitionsVM[TypeKey.resource] = definitions;
        const definitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
        this.sharedDataService.definitionsVM[TypeKey.resource].definitionVM = definitionVM;
      }));
    }
  }

  private prepareNavigationIndex() {
    this.currentMeasureIndex = this.measureService.selectedMeasures ?
      this.measureService.selectedMeasures.findIndex(x => x.id == this.activatedRoute.snapshot.params.id) :
      this.measureService.measures.findIndex(x => x.id == this.activatedRoute.snapshot.params.id);
    this.navigationMaximumIndex = this.measureService.selectedMeasures ? this.measureService.selectedMeasures.length - 1 : this.measureService.measures.length - 1;
  }

  public setData(data) {
    this._data = data;
    if (this.collaboratorsTimeColumns?.length == 0) {
      this.collaboratorsTimeColumns = [
        {
          dataField: 'collaborator',
          dataType: 'string',
          caption: this.dataService.res('Ubt-CreateMeasureTask-Collaborators'),
          width: '60%',
        },
        {
          dataField: 'totalPlannedTime',
          dataType: 'number',
          width: '40%',
          alignment: 'center',
          caption: this.dataService.res('Ubt-CreateMeasureTask-Total-Planned-Time'),
          customizeText: text => { return text.value + 'h'; }
        }
      ];
      this._data[1].forEach(member => {
        const name = member.lastname + ', ' + member.firstname;
        this.totalTimePerCollaboratorPerMonth[name] = 0;
        this.collaboratorGrid.totalTimePerCollaboratorPerMonth[name] = 0;
        this.collaboratorsTimeData.push({
          collaborator: name,
          totalPlannedTime: 0
        });
      });
    }
  }

  public updateData(event) {
    const fullname = typeof (event.name) == 'string' ? event.name : this.collaboratorGrid.getFullnameById(event.name);
    this.collaboratorsTimeData.find(item => (item.collaborator === fullname || item.collaborator === event.fullname)).totalPlannedTime = event.total ? event.total : event.timePlanned;
    this.collaboratorGrid.totalTimePerCollaboratorPerMonth[fullname] = event.total ? event.total : event.timePlanned;
  }

  public calculateTotalTimePerCollaboratorPerMonth() {
    const filtredTasks = this._currentMonth ? this._tasks.filter
      (task => new Date(task?.startDate).getMonth() + 1 === this._currentMonth)
      : this._tasks;
    filtredTasks.forEach(task => {
      task.collaborators?.forEach(col => {
        if (col) {
          const collaborator = this._data[0]?.find(item => item.id === col);
          if (collaborator) {
            const staffMember = this._data[1]?.find(item => item.id === collaborator?.name);
            const fullname = staffMember.lastname + ', ' + staffMember.firstname;
            this.collaboratorsTimeData.find(item => item.collaborator === fullname).totalPlannedTime += collaborator.timePlanned;
            this.collaboratorGrid.totalTimePerCollaboratorPerMonth[fullname] += collaborator.timePlanned;
          }
        }
      });
    });
  }

  ngAfterViewInit(): void {
    this.chart.instance.render();
    this.subscriptions.push();
  }

  public clearWindow(): void {
    this.collaboratorsTimeData.forEach((item, index) => {
      this.collaboratorsTimeData[index].totalPlannedTime = 0;
      this.collaboratorGrid.totalTimePerCollaboratorPerMonth[item.collaborator] = 0;
      this.collaboratorsData = [];
    });
    this.updateTaskMode = false;
    this.measureTask = new MeasureTask(null);
    this.milestoneForm?.instance.resetValues();
    this.taskForm?.instance.resetValues();
    this.isAutoAdjust = false;
    this.milestonePopup.instance.hide();
    this.taskPopup.instance.hide();
    this.createSecondMeasureTask = false;
  }

  public updateMeasure() {
    if (this.sharedDataService.definitionsVM[TypeKey.measure]) {
      const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.measure].measureDefinitionVM.multilingualProperties;
      delete this.measure.meta.updated;
      const query: any = Object.assign({}, this.measure);
      multilingualProperties.forEach(prop => {
        query[prop.key] = { "de": query[prop.key], 'en': query[prop.key] };
      });
      this.subscriptions.push(this.persistenceService.addObjectForUpdate(this.measure.id, query).subscribe(() => {
      }, error => {
        console.error(error);
      }));
    } else {
      this.sharedDataService.getDefinitionAndData<Measure[]>(TypeKey.measure).subscribe(data => {
        this.preparemeasureProgressDefinition();
        const definitions = data[0];
        this.sharedDataService.definitionsVM[TypeKey.measure] = definitions;
        const measureDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
        this.sharedDataService.definitionsVM[TypeKey.measure].measureDefinitionVM = measureDefinitionVM;
        this.measureService.measures = data[1];
        this.currentMeasureIndex = this.measureService.selectedMeasures ?
          this.measureService.selectedMeasures.findIndex(x => x.id == this.activatedRoute.snapshot.params.id) :
          this.measureService.measures.findIndex(x => x.id == this.activatedRoute.snapshot.params.id);
      });
    }
  }

  public verifyChanges(event) {
    if (event.value !== '') {
      this.updateMeasure();
    } else {
      this.measureTitle.value = event.previousValue;
    }
  }

  public updateResource() {
    if (this.assignedResource.id) {
      const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.resource].definitionVM.multilingualProperties;
      delete this.assignedResource['meta'].updated;
      const query: any = Object.assign({}, this.assignedResource);
      multilingualProperties.map(prop => {
        query[prop.key] = { "de": query[prop.key], 'en': query[prop.key] };
      });
      this.subscriptions.push(this.persistenceService.addObjectForUpdate(this.assignedResource.id, query).subscribe(() => {
      }, error => {
        console.error(error);
      }));
    }
  }

  public toggleTitle = (): void => {
    this.toogleTitleVisible = !this.toogleTitleVisible;
  }

  public toggleInformations = (): void => {
    this.toogleInformations = !this.toogleInformations;
  }

  public setResponsible(e) {
    return e ? e.id : null;
  }

  public openConfirmDialog(event): void {
    this.documentId = event.row.data.id;
    this.confirmPopup.instance.show();
  }

  private prepareAssignedResouce = (): void => {
    if (this.measure.resources) {
      this.subscriptions.push(this.coreDataService.getDynamicContentItemById<Resource>(this.measure.resources).subscribe((data) => {
        this.assignedResource = data;
        this.prepareStaffMembers();
      }));
    }
  }

  private prepareStaffMembers = (): void => {
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<StaffMember[]>(TypeKey.staffMember).subscribe((data) => {
      this.assignedStaffmembers = data[1];
      this.cdr.detectChanges();
    }));
  }

  private loadAssignedTasks = (loadOnlyData: boolean): void => {
    this.allowedParentTasks = [];
    this.measureTasks = [];
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<MeasureTask[]>(TypeKey.measureTask).subscribe((data) => {
      const measureTasks = data[1];
      if (loadOnlyData === false) {
        const definitions = data[0];
        const measureDefinitionVM = new DataDefinitionViewModel(definitions, MEASURE_TASK_VISIBLE_PROPERTIES);
        this.measureTasksDefinition = measureDefinitionVM;
        const properties = measureDefinitionVM.properties;
        for (const key in properties) {
          if (properties[key]) {
            const property = properties[key];
            this.tasksColumns.push({
              dataField: property.key,
              caption: property.key === 'targetMaturityLevel' ? 'Target Level' : property.label,
              visible: property.key === 'targetDate' ? false : property?.visible,
              dataType: this.dataService.getDataType(property.type),
              width: property.key === 'label' ? '50%' : (property.key === 'responsible' ? '30%' : '20%'),
              lookup: (this.getLookupColumn(property.key)) ? {
                dataSource: this.getLookupColumn(property.key).dataSource,
                displayExpr: this.getLookupColumn(property.key).displayExpr,
                valueExpr: this.getLookupColumn(property.key).valueExpr
              } : undefined
            });
          }
        }
        this.tasksColumns.push({
          type: "buttons",
          caption: '',
          width: 'auto',
          alignment: 'right',
          dataField: '',
          buttons: [{
            icon: 'add',
            visible: this.setColumnVisibility,
            onClick: (e) => { this.addTask(e); }
          }, {
            icon: 'edit',
            onClick: (e) => { this.openEditMeasureTask(e); }
          }, {
            icon: "trash",
            onClick: (e) => { this.openConfirmTaskDialog(e); }
          }]
        })

      }
      measureTasks.map((measureTask => {
        if (!measureTask['meta'].parent && measureTask?.measure?.toString() === this.activatedRoute.snapshot.params.id.toString()) {
          this.allowedParentTasks.push(measureTask)
        } else {
          measureTask.parentId = measureTask['meta'].parent;
        }
        if (measureTask?.measure?.toString() === this.activatedRoute.snapshot.params.id.toString()) {
          this._tasks.push(measureTask);
          this.measureTasks.push(new MeasureTaskViewModel(measureTask));
        }
        this.cdr.detectChanges();
      }));
    }));
  }

  public loadMeasureProgress = () => {
    if (this.measure.measureProgress?.length > 0) {
      this.measure.measureProgress.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());
      this.measure.measureProgress.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());
      this.progressChartData = ObjectHelper.deepClone(this.measure.measureProgress);
      this.lastMeasureProgress.progress = this.measure.measureProgress[this.measure.measureProgress.length - 1].progress;
      this.lastMeasureProgress.decision = this.measure.measureProgress[this.measure.measureProgress.length - 1].decision;
      this.lastMeasureProgress.nextSteps = this.measure.measureProgress[this.measure.measureProgress.length - 1].nextSteps;
      this.lastMeasureProgress.maturityLevel = this.measure.measureProgress[this.measure.measureProgress.length - 1].maturityLevel;
      this.lastMeasureProgress.riskAndChance = this.measure.measureProgress[this.measure.measureProgress.length - 1].riskAndChance;
      this.lastMeasureProgress.progressPercentage = this.measure.measureProgress[this.measure.measureProgress.length - 1].progressPercentage;
      this.lastMeasureProgress.opportunities = this.measure.measureProgress[this.measure.measureProgress.length - 1].opportunities;
      this.lastMeasureProgress.date = this.measure.measureProgress[this.measure.measureProgress.length - 1].date;
      this.cdr.detectChanges();
    } else {
      this.measure.measureProgress = [];
      this.progressChartData = [];
      this.lastMeasureProgress = new MeasureProgress();
      this.lastMeasureProgress.progressPercentage = 0;
      this.lastMeasureProgress.maturityLevel = this.maturityLevel[0].name;
    }
  }

  public prepareChart() {
    this.progressChartData.forEach((item, index) => {
      const currentItemWeek = this._datepipe.transform(item['date'], 'w');
      let nextItemWeek;
      if (index <= this.progressChartData.length) {
        nextItemWeek = this._datepipe.transform(this.progressChartData[index + 1]?.date, 'w');
        if (currentItemWeek === nextItemWeek) {
          this.progressChartData.splice(index, 1);
        } else if (parseInt(nextItemWeek) - parseInt(currentItemWeek) > 20) {
          this._isCalendarWeek = false;
          this.weeksInterval = 4;
          this.minorTickInterval = 1;
        }
      }
    });
    this.cdr.detectChanges();
  }

  private loadDocuments = (): void => {
    this.subscriptions.push(this.coreDataService.getDocuments<[]>().subscribe(documents => {
      this.documents = documents;
    }));
  }

  public customizeDateText = (e) => {
    const format: string = this._isCalendarWeek == true ? 'w' : 'W';
    return this._datepipe.transform(e.value, format);
  }

  public customizePercentText = (e) => {
    return e.value + '%';
  }

  public getMaturityLevelDescription = (e) => {
    return this.dataService.res('Ubt-MeasureDetail-' + e.value);
  }

  public assginDocument(e): void {
    if (e.value) {
      this.measure.documents.push(e.value);
      this.updateMeasure();
      this.addDocumentSelectBox.instance.reset();
    }
  }

  public openEditMeasureTask = (event) => {
    this.submitButtonText = 'Update';
    this.measureTask = event.row.data.measureTask;
    this.updateTaskMode = true;
    this.cdr.detectChanges();
    this.collaboratorsData = this.measureTask.collaborators;
    if (this.measureTask.parentId) {
      this.priorities[1].disabled = false;
      this.isNewTask = false;
      this.showTaskParents = true;
      this._currentMonth = new Date(event.row.data.startDate).getMonth() + 1;
      if (this._data[1]) {
        this.calculateTotalTimePerCollaboratorPerMonth();
      }
      this.taskPopup.title = this.dataService.res('Ubt-MeasureTask-Update-Task');
      this.taskPopup.instance.show();
    } else {
      this.showTaskParents = false;
      this.priorities[1].disabled = true;
      this.collaboratorsData = [];
      this.milestonePopup.title = this.dataService.res('Ubt-MeasureTask-Update-Milestone');
      this.milestonePopup.instance.show();
    }
  }

  public persistMeasureTask = (event) => {
    this.meausreTaskTree.instance.endUpdate();
    event.preventDefault();
    if (this.updateTaskMode === true) {
      this.updateTask().subscribe();
    } else {
      this.assignTaskToMeasure();
    }
  }

  public addCollaborator(): void {
    this.collaboratorGrid.addRow();
  }

  public calculateOverallTime(): void {
    if (this.collaboratorGrid?.dataSource.length > 0) {
      this.measureTask.totalTimeRequired = 0;
      this.measureTask.totalTimeExecuted = 0;
      this.collaboratorGrid.dataSource.forEach(entry => {
        this.measureTask.totalTimeRequired = this.measureTask.totalTimeRequired + entry.timePlanned;
        this.measureTask.totalTimeExecuted = this.measureTask.totalTimeExecuted + entry.timeExecuted;
      })
    }
  }

  public updateTask = (isCollaboratorsUpdated?: boolean) => {
    delete this.measureTask.meta.updated;
    const collaboratorQueries = new Array<Observable<Collaborator>>();
    let operator: Observable<any>;
    if (this.measureTask.parentId) {
      this.measureTask.meta.parent = this.measureTask.parentId;
      if (this.assignedCollaborators.length > 0 && !isCollaboratorsUpdated) {
        this.assignedCollaborators.forEach(collaboratorQuery => {
          collaboratorQueries.push(this.collaboratorGrid.persistCollaborators(collaboratorQuery));
        })
      }
      delete this.measureTask.parentId;
    }
    const measureTaskId = this.measureTask.id;
    const measureTask: MeasureTask = new MeasureTask(this.measureTask);
    let query = this.measureService.prepareMeasureTaskPersistObject(measureTask);
    if (collaboratorQueries.length > 0) {
      operator = forkJoin(collaboratorQueries);
    } else {
      operator = of({});
    }
    return operator.pipe(map(collaborators => {
      if (collaborators?.length > 0) {
        collaborators?.forEach(collaborator => {
          (query.collaborators ??= []).push(collaborator.id);
        });
      }
      this.assignedCollaborators = [];
      return query
    }), concatMap((updatedQuery) => {
      if (updatedQuery) {
        query = updatedQuery;
      }
      const multilingualProperties = this.measureTasksDefinition.multilingualProperties;
      const listProperties = this.measureTasksDefinition.listProperties;
      const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
      return this.persistenceService.addObjectForUpdate(measureTaskId, object)
        .pipe(map(() => {
          this.updateTaskMode = false;
          if (this.createSecondMeasureTask == true) {
            this.measureTask = new MeasureTask(null);
            this.milestoneForm.instance.resetValues();
            this.isAutoAdjust = false;
          } else {
            this.clearWindow();
          }
          this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
          this._tasks = [];
          this.sharedDataService.getDefinitionAndData<Collaborator[]>(TypeKey.collaborator).subscribe(collaborators => {
            this._data[0] = collaborators[1];
          });
          this.loadAssignedTasks(true);
          this.latestAssignedParent = undefined;
        }))
    }))
  }

  public assignTaskToMeasure = () => {
    this.measureTask.measure = this.activatedRoute.snapshot.params.id;
    let parentId: number;
    let operator: Observable<any>;
    if (this.measureTask.parentId) {
      parentId = this.measureTask.parentId;
      delete this.measureTask.parentId;
    } else {
      this.assignedCollaborators = [];
    }
    this.measureTask.startDate?.setDate(this.measureTask.startDate.getDate() + 1);
    const query = this.measureService.prepareMeasureTaskPersistObject(this.measureTask);

    const collaboratorQueries = new Array<Observable<Collaborator>>();
    if (this.showTaskParents) {
      if (parentId) {
        if (this.assignedCollaborators.length > 0) {
          this.assignedCollaborators.forEach(collaboratorQuery => {
            collaboratorQueries.push(this.collaboratorGrid.persistCollaborators(collaboratorQuery));
          })
        }
      }
    }
    if (collaboratorQueries.length > 0) {
      operator = forkJoin(collaboratorQueries);
    } else {
      operator = of({});
    }
    return operator.pipe(map(collaborators => {
      if (collaborators?.length > 0) {
        collaborators?.forEach(collaborator => {
          (query.collaborators ??= []).push(collaborator.id);
        });
        this.assignedCollaborators = [];

      }
      return query
    }), concatMap((query) => {
      const multilingualProperties = this.measureTasksDefinition.multilingualProperties;
      const listProperties = this.measureTasksDefinition.listProperties;
      const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
      return this.persistenceService.addObjectForInsert(TypeKey.measureTask, object)
    }), concatMap((response: MeasureTask) => {
      if (parentId) {
        response.parentId = parentId;
        this.measureTask = response;
        return this.updateTask(true);
      } else {
        return of({});
      }
    })
    ).subscribe(() => {
      if (!parentId) {
        this.loadAssignedTasks(true);
      }
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
      if (this.createSecondMeasureTask == true) {
        this.measureTask = new MeasureTask(null);
        this.taskForm.instance.resetValues();
        this.measureTask.parentId = parentId;
        this.isAutoAdjust = false;
      } else {
        this.clearWindow();
      }
      this.latestAssignedParent = undefined;
      this.sharedDataService.getDefinitionAndData<Collaborator[]>(TypeKey.collaborator).subscribe(collaborators => {
        this._data[0] = collaborators[1];
      });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public deleteTask(event): void {
    this.subscriptions.push(this.persistenceService.addObjectForDelete(this.measureTaskId).subscribe(() => {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
      this.confirmTaskPopup.instance.hide();
      this._tasks = [];
      this.loadAssignedTasks(true);
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public onCellPrepared = (e): void => {
    if (e.column.dataType === 'number') {
      e.cellElement.style.textAlignLast = 'center';
    }
  }

  public openConfirmTaskDialog(event): void {
    this.measureTaskId = event.row.data.id || event.row.key;
    this.confirmTaskPopup.instance.show();
  }

  public addDocument = () => {
    this.addDocumentPopup.instance.show();
  }

  public closeDocumentPopUp = () => {
    this.fileUploader.instance.reset();
    this.addDocumentPopup.instance.hide();
  }

  public uploadFile = (file) => {
    let xhr = this.coreDataService.importDocument(file, file.name);
    let that = this;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        const response = JSON.parse(this.responseText)
        that.measure.documents.push(response);
        that.updateMeasure();
        that.loadDocuments();
      }
    });
  }

  public setParentId(e) {
    if (this.showTaskParents === false) {
      this.latestAssignedParent = this.measureTask.parentId;
      this.measureTask.parentId = undefined;
      if (this.measureTask.meta?.parent) {
        delete this.measureTask.meta.parent;
      }
    } else {
      if (this.latestAssignedParent) this.measureTask.parentId = this.latestAssignedParent;
    }
  }

  public abortDelete() {
    this.confirmPopup.instance.hide();
  }

  public abortTaskDelete() {
    this.confirmTaskPopup.instance.hide();
  }

  public removeRow(event): void {
    if (this.measure.documents.length > 1) {
      let index = this.measure.documents.findIndex(element => element.id == this.documentId);
      this.measure.documents.splice(index, 1);
    } else {
      this.measure.documents = [];
    }
    this.measureDokumenteGrid.gridData = this.measure.documents;
    this.updateMeasure();
    this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
    this.confirmPopup.instance.hide();
  }

  public downloadDocument = (id: number): void => {
    this.showLoader = true;
    this.subscriptions.push(this.coreDataService.getDocumentFormat(id).pipe(switchMap(assets => {
      return this.coreDataService.getBlobFile(id)
        .pipe(map(blobFile => ({ assets, blobFile })));
    }))
      .subscribe(({ assets, blobFile }) => {
        const assetsMime = assets.formats.filter(format => format.name === 'source');
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob([blobFile], { type: assetsMime[0].mimeType }));
        downloadLink.setAttribute('download', assetsMime[0].filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }, error => {
        console.error(error);
        this.showLoader = false;
        this.cdr.markForCheck();

      }, () => {
        this.showLoader = false;
        this.cdr.markForCheck();
      }));
  }

  public onResponsibleChange(e): void {
    this.measure.responsible = this.responsibles[this.responsibles.findIndex(x => x.id == e.value)]
    this.updateMeasure();
    this.prepareStaffMembers();

  }

  public backToMeasureList(): void {
    this.dataService.appService.showMeasureIcon = false;
    if (this.returnPath === "subProject") {
      this.router.navigate(["subProjectDetail", this.measure.subProject.id]);
    } else {
      this.router.navigateByUrl(this.returnPath);
    }
  }

  public assignMeasureReport = () => {
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.measureProgress].definitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.measureProgress].definitionVM.listProperties;
    const query = this.sharedDataService.createPersistObject(this.lastMeasureProgress, multilingualProperties, listProperties);
    this.persistenceService.addObjectForInsert(TypeKey.measureProgress, query).subscribe((measureProgress) => {
      const measurePR: MeasureProgress = measureProgress
      this.measure.measureProgress.push(measureProgress);
      this.progressChartData.push(measureProgress);
      this.prepareChart();
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }, () => {
      this.updateMeasure();
      this.isNewMeasureTask = !this.isNewMeasureTask;
      this.cdr.detectChanges();
    });;
  }

  private preparemeasureProgressDefinition(): void {
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<MeasureProgress[]>(TypeKey.measureProgress).subscribe(data => {
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.measureProgress] = definitions;
      const definitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.measureProgress].definitionVM = definitionVM;
    }));
  }

  public clearProgressFileds = (): void => {
    if (this.workOnLastStatus == false) {
      this.lastMeasureProgress = new MeasureProgress();
    } else {
      delete this.lastMeasureProgress['meta'];
      delete this.lastMeasureProgress.id;
    }
    this.isNewMeasureTask = true;
    this.lastMeasureProgress.date = new Date();
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'degreeOfImplementation':
      case 'efficiency':
      case 'Riskoreduktion':
        return { valueExpr: 'id', displayExpr: 'value', dataSource: this.measureService.degree };
      case 'responsible':
        return { valueExpr: 'id', displayExpr: this.dataService.getResponsibleFullName, dataSource: this.responsibles };
      case 'targetMaturityLevel':
        return { valueExpr: 'value', displayExpr: 'name', dataSource: this.measureService.maturityLevel };
    }
  }

  public navigateToMeasure = (index: number): void => {
    this.showLoader = true;
    this.measureTasks = [];
    this.assignedResource = new Resource();
    this.currentMeasureIndex = this.currentMeasureIndex + index;
    const measureDetail = this.measureService.selectedMeasures ?
      new Measure(this.measureService.selectedMeasures[this.currentMeasureIndex]) :
      new Measure(this.measureService.measures[this.currentMeasureIndex]);
    const measureData = forkJoin([
      this.coreDataService.getDynamicContentItemById<Measure>(measureDetail.id),
      this.coreDataService.getCommentsByItemId<Comment[]>(measureDetail.id),
    ]);
    measureData.subscribe(data => {
      this.router.navigate(['measureDetail', 'measures', measureDetail.id]);
      this.measureComments = data[1];
      this.measureComments.forEach(element => {
        element.avatar = this.dataService.cachedProfileAvatar[element.author.id];
      });
      this.measure = data[0];
      this.loadMeasureProgress();
      this.loadAssignedTasks(true);
      this.prepareAssignedResouce();
    }, (error) => {
      console.error(error)
    }, () => {
      this.isNewMeasureTask = false;
      this.workOnLastStatus = false;
      this.showLoader = false;
      this._tasks = [];
    })
  }

  public addMilestone = (): void => {
    this.submitButtonText = 'Create';
    this.milestonePopup.title = this.dataService.res('Ubt-MeasureTask-Create-Milestone');
    this.priorities[1].disabled = true;
    this.updateTaskMode = false;
    this.showTaskParents = false;
    this.milestonePopup.instance.show();
  }

  private addTask = (e): void => {
    this.submitButtonText = 'Create';
    this.taskPopup.title = this.dataService.res('Ubt-MeasureTask-Create-Task');
    this.showTaskParents = true;
    this.measureTask.parentId = e.row.data.id;
    this.priorities[1].disabled = false;
    this.isNewTask = true;
    this.collaboratorsData = [];
    this.cdr.detectChanges();
    this.taskForm.instance.resetValues();
    this.taskPopup.instance.show();
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }

  public setColumnVisibility(e) {
    if (e.row.level === 0)
      return true;
    else
      return false
  }

}

export interface CollaboratorPlannedTime {
  collaborator: string;
  totalPlannedTime: number;
}