import { Collaborator, MeasureProgress, MeasureRecommendation, NamedItem, Resource } from '@wissenswerft/organizational-structure';
import { ProfileInfo } from 'libs/core/authentication/src/lib/models/profile-info.model';
import { Meta } from './customer-space.model';

export enum Status {
    open = 'Offen',
    inprogress = 'In Bearbeitung',
    done = 'Erledigt'
}

export enum PrevDect {
    PREVENTIVE = 'Preventive',
    DETECTIVE = 'Detective'
}

export class Measure extends MeasureRecommendation {
    currentState: string; // RichText
    status: Status;
    startDate: Date;
    endDate: Date;
    measureDefinitions: MeasureDefinition[];
    prevDect: PrevDect;
    responsible: ProfileInfo;
    concept: string;
    defenseMeasures: string;
    dependencies: string;
    expectedResults: string;
    measureProgress: MeasureProgress[];
    resources : Resource;
    documents: Document[];
    currentGrade : string;
    meta: Meta;
    constructor(data) {
        super(data);
        Object.assign(this, data);
    }
}

export class MeasureClassification extends NamedItem { }

export class MeasureDefinition extends NamedItem {
    constructor(data) {
        super();
        Object.assign(this, data);
    }
}

export class MeasureTask extends NamedItem {
    notes: string; //RichText
    degreeOfImplementation: number;
    efficiency: number;
    measure: MeasureRecommendation;
    responsible : ProfileInfo;
    totalTimeRequired : number;
    totalTimeExecuted : number;
    targetDate : Date;
    startDate : Date;
    targetMaturityLevel: string;
    parentId? : number;
    collaborators : number[]
    meta: Meta;
    constructor(data) {
        super();
        Object.assign(this, data);
    }
}

export interface Document {
    id : number;
    hidden: boolean;
    thumbnailUri: string;
    timeOfLastFormatUpdate : Date;
    title: string;
}

export enum OrgaTech {
    ORGANIZATIONAL = 'Organizational',
    TECHNICAL = 'Technical'
}