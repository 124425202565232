import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ProfileInfo } from '@wissenswerft/core/authentication';

import { SpaceOneCoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';

import { Role, SharedDataService, StaffMember } from '@wissenswerft/organizational-structure';

import { GridComponent, ToastType } from '@wissenswerft/ww-library';

import { DxFormComponent, DxPopupComponent } from 'devextreme-angular';

import { Column } from 'devextreme/ui/data_grid';

import { Observable, Subscription } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';
import { AppService } from '../../services/app.service';

import { DataService } from '../../services/data.service';

import { DataDefinitionViewModel } from '../../view-models/customer-space.view-model';

import { StaffMemberViewModel, VISIBLE_PROPERTIES } from '../../view-models/staff-member.view-model';

import { StaffMembersService } from './staff-members.service';

@Component({
  selector: 'ubt-staff-member',
  templateUrl: './staff-members.component.html',
  styleUrls: ['./staff-members.component.scss']
})
export class StaffMembersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('staffMembersGrid') staffMembersGrid: GridComponent;

  @ViewChild('createMemberPopup') createMemberPopup: DxPopupComponent;
  @ViewChild('form') form: DxFormComponent;

  public staffMember: StaffMember = new StaffMember(null);

  public title: string;

  public pluralTitle: string;

  public staffMembers: StaffMemberViewModel[] = [];

  public showLoader = false;

  public responsibles: ProfileInfo[];

  public availableRoles: Role[] = [];

  public columnsHeader: Column[] = [];

  private subscriptions: Subscription[] = [];
  public createButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Create'),
    useSubmitBehavior: true
  };
  public cancelButtonOptions = {
    text: this.dataService.res('Ubt-CreateMeasure-Cancel'),
    onClick: () => this.clearWindow()
  };
  private allowUpdating: boolean;

  constructor(
    public dataService: DataService,

    private persistenceService: PersistenceService,

    private coreDataService: SpaceOneCoreDataService,

    private sharedDataService: SharedDataService,

    public staffMembersService: StaffMembersService,

    public appService : AppService
  ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.staffMember = new StaffMember(null);
    if (this.sharedDataService.definitionsVM[TypeKey.staffMember]) {
      this.title = this.sharedDataService.definitionsVM[TypeKey.staffMember].name;
    }

    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<StaffMember[]>(TypeKey.staffMember).pipe(switchMap(data => {
      return this.coreDataService.getAllAccounts<ProfileInfo[]>()
        .pipe(switchMap(responsibles => {
          return this.sharedDataService.getDefinitionAndData<Role[]>(TypeKey.role)
            .pipe(map(roles => ({ data, responsibles, roles })));
        }))
    })).subscribe(({ data, responsibles, roles }) => {
      this.availableRoles = roles[1];
      this.responsibles = responsibles;
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.staffMember] = definitions;
      this.title = definitions.name;
      this.pluralTitle = definitions.namePlural;
      const staffMembers = data[1];
      const staffMembersDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.staffMember].definitionVM = staffMembersDefinitionVM;
      const properties = staffMembersDefinitionVM.properties;
      for (const key in properties) {
        if (properties[key] && properties[key].key !== 'ident') {
          const property = properties[key];
          this.columnsHeader.push({
            dataField: property.key,
            caption: property.label,
            visible: property?.visible,
            dataType: this.dataService.getDataType(property.type),
            groupIndex: (property.key === 'responsible') ? 0 : undefined,
            lookup: (this.getLookupColumn(property.key)) ? {
              dataSource: this.getLookupColumn(property.key).dataSource,
              displayExpr: this.getLookupColumn(property.key).displayExpr,
              valueExpr: this.getLookupColumn(property.key).valueExpr
            } : undefined,
          });
        }
      }
      staffMembers.map((staffMember: StaffMember) => {
        this.staffMembers.push(new StaffMemberViewModel(staffMember));
      });
    }, error => {
      console.error(error);
      this.showLoader = false;
    }, () => {
      this.showLoader = false;
    }));

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((staffMember: StaffMemberViewModel) => {
      this.staffMembers.push(staffMember);
    }));
  }

  ngAfterViewInit(): void {

  }

  public removeRow(event): void {
    const staffMemberId = event.data.id || event.data.staffMember.id;
    this.subscriptions.push(this.persistenceService.addObjectForDelete(staffMemberId).subscribe(() => {
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Delete"), type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public updateRow(event): void {
    if (this.allowUpdating === true) {
      const staffMemberId = event.data.id || event.data.staffMember.id;
      const staffMember: StaffMember = new StaffMember(event.data.staffMember);
      const query = this.staffMembersService.prepareMembersPersistObject(staffMember);
      const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.staffMember].definitionVM.multilingualProperties;
      const listProperties = this.sharedDataService.definitionsVM[TypeKey.staffMember].definitionVM.listProperties;
      const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
      this.subscriptions.push(this.persistenceService.addObjectForUpdate(staffMemberId, object).subscribe(() => {
        this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
      }, error => {
        this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
      })); 
    }
  }

  public verifyChanges(event) {
    if (event.newData?.firstname !== '' && event.newData?.lastname !== '') {
      this.allowUpdating = true;
    } else {
      event.cancel = true;
      this.allowUpdating = false;
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Empty"), type: ToastType.INFO });
    }
  }

  public openStaffMemberDialog(): void {
    this.createMemberPopup.instance.show();
  }

  public createStaffMember(event): void {
    this.staffMember.ident= this.staffMember.title
    this.subscriptions.push(this.persistObject().subscribe((data: StaffMember) => {
      const staffMember = new StaffMemberViewModel(data);
      this.sharedDataService.updateGridData(staffMember);
      this.dataService.appService.callNotification({ message: this.dataService.res("Ubt-Notification-Success"), type: ToastType.SUCCESS });
      this.clearWindow();
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
    event.preventDefault();
    this.staffMember = new StaffMember(null);
  }

  private persistObject(): Observable<StaffMember> {
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.staffMember].definitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.staffMember].definitionVM.listProperties;
    const query = this.sharedDataService.createPersistObject(this.staffMember, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.staffMember, query);
  }

  public clearWindow(): void {
    this.createMemberPopup.instance.hide();
    this.form.instance.resetValues();
    this.staffMember = new StaffMember(null);
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'responsible':
        return { valueExpr: 'id', displayExpr: this.dataService.getResponsibleFullName, dataSource: this.responsibles };
      case 'role':
        return { valueExpr: 'id', displayExpr: 'name', dataSource: this.availableRoles };
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => { subscription.unsubscribe(); });
  }
}